import {CardResult, _CardResult} from './../card-result';
import {Card} from './../card';
import {SummaryCardBuilder} from './../common/summary-card-builder';
import {_BivariateCard} from './../bivariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateSummaryStatsCard
 */
export interface BivariateSummaryStatsCard extends _BivariateCard {
    compute: BivariateSummaryStatsCard.StatsToCompute;
    confidenceLevel: number;
    showConfidenceInterval: boolean;

    // PolyJson type
    type: 'bivariate_summary';
}

export function isBivariateSummaryStatsCard(toTest: Card): toTest is BivariateSummaryStatsCard { return 'bivariate_summary' === toTest.type; }

export namespace BivariateSummaryStatsCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateSummaryStatsCard$BivariateSummaryStatsCardResult
     */
    export interface BivariateSummaryStatsCardResult extends _CardResult {
        stats: SummaryCardBuilder.ComputedStat[];

        // PolyJson type
        type: 'bivariate_summary';
    }

    export function isBivariateSummaryStatsCardResult(toTest: CardResult): toTest is BivariateSummaryStatsCardResult { return 'bivariate_summary' === toTest.type; }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateSummaryStatsCard$StatsToCompute
     */
    export interface StatsToCompute {
        completeCount: boolean;
        covariance: boolean;
        kendallTau: boolean;
        mutualInformation: boolean;
        pearson: boolean;
        spearman: boolean;
        totalCount: boolean;
    }
}