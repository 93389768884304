import {Computation, _Computation} from './../computation';
import {ComputationResult} from './../computation-result';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.common.Count
 */
export interface Count extends _Computation {
    // PolyJson type
    type: 'count';
}

export function isCount(toTest: Computation): toTest is Count { return 'count' === toTest.type; }

export namespace Count {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.common.Count$CountResult
     */
    export interface CountResult extends _AvailableResult {
        count: number;

        // PolyJson type
        type: 'count';
    }

    export function isCountResult(toTest: ComputationResult): toTest is CountResult { return 'count' === toTest.type; }
}