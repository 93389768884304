import { Card, Filter } from 'src/generated-sources';

export enum CardActionType {
    DELETE,
    UPDATE,
    ADD,
    DEBUG,
    PUBLISH,
    HIGHLIGHT,
    REORDER_HEADER_CARD
}

export interface UpdateCardAction {
    type: CardActionType.UPDATE;
    newParams: Card;
    updateSiblings?: boolean;
    immediate?: boolean;
}

export interface DeleteCardAction {
    type: CardActionType.DELETE;
}

export interface AddCardAction {
    type: CardActionType.ADD;
    card: Card;
}

export interface HighlightAction {
    type: CardActionType.HIGHLIGHT;
    filter?: Filter | null;
}

export interface DebugCardAction {
    type: CardActionType.DEBUG;
    card: Card;
}

export interface PublishCardAction {
    type: CardActionType.PUBLISH;
    card: Card;
}

export interface ReorderColumnsAction {
    type: CardActionType.REORDER_HEADER_CARD;
    cardId: string;
    previousIndex: number;
    currentIndex: number;
}

export type CardAction = DeleteCardAction | UpdateCardAction | AddCardAction | DebugCardAction
    | PublishCardAction | ReorderColumnsAction | HighlightAction;
