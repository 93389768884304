import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.ZTest1Samp
 */
export interface ZTest1Samp extends Computation._UnivariateComputation {
    hypothesizedMean: number;
    knownStdDev: number;

    // PolyJson type
    type: 'ztest_1samp';
}

export function isZTest1Samp(toTest: Computation): toTest is ZTest1Samp { return 'ztest_1samp' === toTest.type; }

export namespace ZTest1Samp {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.ZTest1Samp$ZTest1SampResult
     */
    export interface ZTest1SampResult extends _AvailableResult {
        pvalue: number;
        pvalueAltGt: number;
        pvalueAltLt: number;
        statistic: number;

        // PolyJson type
        type: 'ztest_1samp';
    }

    export function isZTest1SampResult(toTest: ComputationResult): toTest is ZTest1SampResult { return 'ztest_1samp' === toTest.type; }
}