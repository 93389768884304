<table class="fit-table">
    <tbody>
        <tr>
            <th
                class="fit-table__head"
                width="50%"
            >
                Probability density function
            </th>
            <th
                class="fit-table__head"
                width="50%"
            >
                Q-Q plot
            </th>
        </tr>
        <tr>
            <td class="fit-table__cell">
                <lazy-echart class="chart"
                    [options]="densityChartOptions"
                ></lazy-echart>
            </td>
            <td class="fit-table__cell">
                <lazy-echart class="chart"
                    [options]="qqPlotOptions"
                ></lazy-echart>
            </td>
        </tr>
    </tbody>
</table>

<table class="fit-table">
    <tbody>
        <tr>
            <th class="fit-table__head">Distribution</th>
            <th
                class="fit-table__head"
                colspan="2"
            >
                Goodness of fit
            </th>
            <th class="fit-table__head">Estimated parameters</th>
        </tr>
        <tr *ngFor="let fitResult of results.distributions; let distributionIndex = index;">
            <th class="fit-table__head">
                <!-- TODO: avoid inline style -->
                <span [ngStyle]="{color: colorsService.getColorFromIndex(distributionIndex), 'font-size':'30px'}">&bull;</span>
                {{ fitResult.distribution | distributionName }}
            </th>
            <td class="fit-table__cell">
                <table class="stats-table">
                    <tbody
                        class="stats-table__body"
                        *ngIf="fitResult.test.ks"
                    >
                        <tr class="stats-table__row">
                            <th
                                class="stats-table__title"
                                colspan="2"
                            >
                                Kolmogorov–Smirnov test
                            </th>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">statistic</th>
                            <td class="stats-table__cell">{{ fitResult.test.ks.statistic | smarterNumber }}</td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">𝘱-value</th>
                            <td class="stats-table__cell">
                                <colored-pvalue
                                    [pvalue]="fitResult.test.ks.pvalue"
                                    [significanceLevel]="1 - params.confidenceLevel"
                                ></colored-pvalue>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
            <td class="fit-table__cell">
                <table
                    class="stats-table"
                    *ngIf="fitResult.test.ll"
                >
                    <tbody class="stats-table__body">
                        <tr class="stats-table__row">
                            <th
                                class="stats-table__title"
                                colspan="2"
                            >
                                Likelihood metrics
                            </th>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Log-likelihood</th>
                            <td class="stats-table__cell">{{ fitResult.test.ll.ll }}</td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">AIC</th>
                            <td class="stats-table__cell">{{ fitResult.test.ll.aic }}</td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">AICc</th>
                            <td class="stats-table__cell">{{ fitResult.test.ll.aicc }}</td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">BIC</th>
                            <td class="stats-table__cell">{{ fitResult.test.ll.bic }}</td>
                        </tr>
                    </tbody>
                </table>
            </td>
            <td class="fit-table__cell">
                <table class="stats-table">
                    <tbody>
                        <tr class="stats-table__row">
                            <th
                                class="stats-table__title"
                                colspan="2"
                            >
                                {{ fitResult.distribution | distributionName }} parameters
                            </th>
                        </tr>
                        <ng-container [ngSwitch]="fitResult.fit.type">
                            <ng-container *ngSwitchCase="'normal'">
                                <tr class="stats-table__row">
                                    <th class="stats-table__head">Mean</th>
                                    <td class="stats-table__cell">{{ fitResult.fit.mean | smarterNumber }}</td>
                                </tr>
                                <tr class="stats-table__row">
                                    <th class="stats-table__head">Standard deviation</th>
                                    <td class="stats-table__cell">{{ fitResult.fit.std | smarterNumber }}</td>
                                </tr>
                            </ng-container>

                            <ng-container *ngSwitchCase="'beta'">
                                <tr class="stats-table__row">
                                    <th class="stats-table__head">Alpha</th>
                                    <td class="stats-table__cell">{{ fitResult.fit.alpha | smarterNumber }}</td>
                                </tr>
                                <tr class="stats-table__row">
                                    <th class="stats-table__head">Beta</th>
                                    <td class="stats-table__cell">{{ fitResult.fit.beta | smarterNumber }}</td>
                                </tr>
                                <tr class="stats-table__row">
                                    <th class="stats-table__head">Lower</th>
                                    <td class="stats-table__cell">{{ fitResult.fit.lower | smarterNumber }}</td>
                                </tr>
                                <tr class="stats-table__row">
                                    <th class="stats-table__head">Upper</th>
                                    <td class="stats-table__cell">{{ fitResult.fit.upper | smarterNumber }}</td>
                                </tr>
                            </ng-container>

                            <ng-container *ngSwitchCase="'triangular'">
                                <tr class="stats-table__row">
                                    <th class="stats-table__head">a</th>
                                    <td class="stats-table__cell">{{ fitResult.fit.a | smarterNumber }}</td>
                                </tr>
                                <tr class="stats-table__row">
                                    <th class="stats-table__head">b</th>
                                    <td class="stats-table__cell">{{ fitResult.fit.b | smarterNumber }}</td>
                                </tr>
                                <tr class="stats-table__row">
                                    <th class="stats-table__head">c</th>
                                    <td class="stats-table__cell">{{ fitResult.fit.c | smarterNumber }}</td>
                                </tr>
                            </ng-container>

                            <ng-container *ngSwitchCase="'exponential'">
                                <tr class="stats-table__row">
                                    <th class="stats-table__head">Rate</th>
                                    <td class="stats-table__cell">{{ fitResult.fit.rate | smarterNumber }}</td>
                                </tr>
                            </ng-container>

                            <ng-container *ngSwitchCase="'lognormal'">
                                <tr class="stats-table__row">
                                    <th class="stats-table__head">Logmean</th>
                                    <td class="stats-table__cell">{{ fitResult.fit.logmean | smarterNumber }}</td>
                                </tr>
                                <tr class="stats-table__row">
                                    <th class="stats-table__head">Logstd</th>
                                    <td class="stats-table__cell">{{ fitResult.fit.logstd | smarterNumber }}</td>
                                </tr>
                            </ng-container>

                            <ng-container *ngSwitchCase="'weibull'">
                                <tr class="stats-table__row">
                                    <th class="stats-table__head">Shape</th>
                                    <td class="stats-table__cell">{{ fitResult.fit.shape | smarterNumber }}</td>
                                </tr>
                                <tr class="stats-table__row">
                                    <th class="stats-table__head">Scale</th>
                                    <td class="stats-table__cell">{{ fitResult.fit.scale | smarterNumber }}</td>
                                </tr>
                            </ng-container>

                            <ng-container *ngSwitchCase="'pareto'">
                                <tr class="stats-table__row">
                                    <th class="stats-table__head">Shape</th>
                                    <td class="stats-table__cell">{{ fitResult.fit.shape | smarterNumber }}</td>
                                </tr>
                                <tr class="stats-table__row">
                                    <th class="stats-table__head">Scale</th>
                                    <td class="stats-table__cell">{{ fitResult.fit.scale | smarterNumber }}</td>
                                </tr>
                            </ng-container>

                            <ng-container *ngSwitchCase="'laplace'">
                                <tr class="stats-table__row">
                                    <th class="stats-table__head">Mu</th>
                                    <td class="stats-table__cell">{{ fitResult.fit.mu | smarterNumber }}</td>
                                </tr>
                                <tr class="stats-table__row">
                                    <th class="stats-table__head">b</th>
                                    <td class="stats-table__cell">{{ fitResult.fit.b | smarterNumber }}</td>
                                </tr>
                            </ng-container>

                            <ng-container *ngSwitchCase="'normal_mixture'">
                                <ng-container *ngFor="let component of fitResult.fit.components; let componentIndex = index;">
                                    <tr class="stats-table__row">
                                        <th
                                            class="stats-table__title"
                                            colspan="2"
                                        >
                                            Component {{ componentIndex +1 }}
                                        </th>
                                    </tr>
                                    <tr class="stats-table__row">
                                        <th class="stats-table__head">Mean</th>
                                        <td class="stats-table__cell">{{ component.mean | smarterNumber }}</td>
                                    </tr>
                                    <tr class="stats-table__row">
                                        <th class="stats-table__head">Standard deviation</th>
                                        <td class="stats-table__cell">{{ component.std | smarterNumber }}</td>
                                    </tr>
                                    <tr class="stats-table__row">
                                        <th class="stats-table__head">Weight</th>
                                        <td class="stats-table__cell">{{ component.weight | smarterNumber }}</td>
                                    </tr>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngSwitchDefault>
                                <tr class="stats-table__row">
                                    <td class="stats-table__cell">NOT IMPLEMENTED</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table>
