import {BivariateHistogramCard} from './../bivariate_header/bivariate-histogram-card';
import {BoxPlotFragment} from './../fragments/box-plot-fragment';
import {CardResult, _CardResult} from './../card-result';
import {Card} from './../card';
import {Filter} from './../../../compute/filtering/filter';
import {MoodTestNSampCard} from './../mood-test-nsamp-card';
import {OneWayANOVACard} from './../one-way-anovacard';
import {PairwiseMoodTestCard} from './../pairwise-mood-test-card';
import {PairwiseTTestCard} from './../pairwise-ttest-card';
import {_BivariateCard} from './../bivariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.common.AbstractNSampTestCard
 */
export interface _AbstractNSampTestCard extends _BivariateCard {
    confidenceLevel: number;
    maxGroups: number;
    yValues?: string[] | null;
}

export type AbstractNSampTestCard = MoodTestNSampCard | OneWayANOVACard | PairwiseTTestCard | PairwiseMoodTestCard;

export function isAbstractNSampTestCard(toTest: Card): toTest is AbstractNSampTestCard { return ["mood_nsamp" , "oneway_anova" , "pairwise_ttest" , "pairwise_mood"].includes(toTest.type); }

export namespace AbstractNSampTestCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.common.AbstractNSampTestCard$AbstractNSampleTestCardResult
     */
    export interface _AbstractNSampleTestCardResult extends _CardResult {
        allGroups: AbstractNSampTestCard.GroupInfo;
        groups: AbstractNSampTestCard.GroupInfo[];
        histogramData: BivariateHistogramCard.BivariateHistogramData;
    }

    export type AbstractNSampleTestCardResult = MoodTestNSampCard.MoodTestNSampCardResult | OneWayANOVACard.OneWayANOVACardResult | PairwiseMoodTestCard.PairwiseMoodTestCardResult | PairwiseTTestCard.PairwiseTTestCardResult;

    export function isAbstractNSampleTestCardResult(toTest: CardResult): toTest is AbstractNSampleTestCardResult { return ["mood_nsamp" , "oneway_anova" , "pairwise_mood" , "pairwise_ttest"].includes(toTest.type); }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.common.AbstractNSampTestCard$GroupInfo
     */
    export interface GroupInfo {
        boxPlot: BoxPlotFragment;
        filter: Filter;
    }
}