import {JointNormal} from './joint-normal';
import {KDE2D} from './kde2-d';

/**
 * Generated from com.dataiku.dip.eda.compute.distributions.Distribution2D
 */
export interface _Distribution2D {}

export type Distribution2D = KDE2D | JointNormal;

export namespace Distribution2D {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.Distribution2D$FittedDistribution2D
     */
    export interface _FittedDistribution2D {}

    export type FittedDistribution2D = JointNormal.FittedJointNormal | KDE2D.FittedKDE2D;

    export function isFittedDistribution2D(toTest: Distribution2D.FittedDistribution2D): toTest is FittedDistribution2D { return ["joint_normal" , "kde_2d"].includes(toTest.type); }
}