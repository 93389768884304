import { Pipe, PipeTransform } from '@angular/core';
import { fairAny } from 'dku-frontend-core';

declare let d3: fairAny;

@Pipe({
    name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {

    transform(num: number, singular?: string, plural?: string, d3Formatter?: (n: number|any) => string, no?: boolean): string {
        if (no && num == 0) {
            return no === true ? 'no ' + plural : no;
        }
        let quantity = no && num == 0 ? 'no' : (d3Formatter ? d3.format(d3Formatter)(num) : num);
        let noun = num > 1 ? plural : singular;
        return quantity + ' ' + noun;
    }

}
