import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.Shapiro
 */
export interface Shapiro extends Computation._UnivariateComputation {
    // PolyJson type
    type: 'shapiro';
}

export function isShapiro(toTest: Computation): toTest is Shapiro { return 'shapiro' === toTest.type; }

export namespace Shapiro {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.Shapiro$ShapiroResult
     */
    export interface ShapiroResult extends _AvailableResult {
        pvalue: number;
        statistic: number;

        // PolyJson type
        type: 'shapiro';
    }

    export function isShapiroResult(toTest: ComputationResult): toTest is ShapiroResult { return 'shapiro' === toTest.type; }
}