import { ProjectsCRUDController } from 'src/generated-sources';
import { Observable } from 'rxjs';
import { DkuHttpService } from 'dku-frontend-core';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class PrivateProjectsAPI {
    constructor(private dkuHttp: DkuHttpService) { }

    getSummary(projectKey: string): Observable<any> {
        return this.dkuHttp.request('GET', '/projects/get-summary', { projectKey });
    }

    listHeads(requiredPrivilege?: string | null): Observable<ProjectsCRUDController.ProjectHead[]> {
        return this.dkuHttp.request('GET', '/projects/list-heads', { requiredPrivilege });
    }
}
