<form [formGroup]="configForm" id="config-form" class="dkuform-modal-horizontal dkuform-modal-horizontal--wizard">
    <div class="control-group">
        <label class="control-label">
            X Variable
        </label>
        <div class="controls">
            <single-variable-selector [variables]="allVariables$ | async" formControlName="xColumn"></single-variable-selector>
        </div>
    </div>
    <div class="control-group">
        <label class="control-label">
            Y Variable
        </label>
        <div class="controls">
            <single-variable-selector [variables]="allVariables$ | async" formControlName="yColumn"></single-variable-selector>
        </div>
    </div>
    <div class="control-group">
        <label class="control-label">
            Curves
        </label>
        <div class="controls">
            <div class="card-editor__rows">
                <editable-list 
                    [itemsFormArray]="configForm.get('curves')"
                    [hasDivider]="false"
                    [focusOnEnter]="false"
                    addLabel="Add a curve"   
                >
                    <ng-template let-item="item" let-index="index" let-goToNext="goToNext">
                        <editable-list-template (onInputEnter)="goToNext($event)">
                            <div class="card-editor__row" >
                                <div class="card-editor__control">
                                    <div class="controls controls--no-label editable-list__input--child-underline">
                                        <dku-bs-select-form-control [list]="fitCurves" [formControl]="item.get('type')"  ngOptions="v.value as v.name for v in list"></dku-bs-select-form-control>
                                    </div>
                                </div>
                                <div class="card-editor__control mleft8" *ngIf="item.get('degree')?.enabled">
                                    <div class="controls controls--no-label">
                                        with degree 
                                        <input type="number" class="editable-list__input editable-list__input--underline card-editor__control-param" [formControl]="item.get('degree')">
                                    </div>
                                </div>
                            </div>
                        </editable-list-template>
                    </ng-template>
                </editable-list>
            </div>
        </div>
    </div>
</form>
