import { Injectable, Inject } from '@angular/core';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { DataSpec, SamplingParam, WorksheetRootCard, Worksheet, WorksheetsService, ContainerExecSelection } from 'src/generated-sources';
import { Observable, BehaviorSubject, Subject, EMPTY, ReplaySubject, of } from 'rxjs';
import { randomId } from '@utils/random';
import { catchAPIError, ErrorContext, APIError } from '@core/dataiku-api/api-error';
import { fairAny } from 'dku-frontend-core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { first, switchMap, distinctUntilChanged, map, startWith, shareReplay, mapTo, filter } from 'rxjs/operators';
import { resetCardIds } from './card-utils';
import { getWorksheetObjectRef, normalizeSmartName, resolveSmartName } from './utils';
import { DEFAULT_DATASET_SELECTION_FIELDS, DEFAULT_TAGGABLE_OBJECT_FIELDS } from '@utils/dss-defaults';

@UntilDestroy()
@Injectable()
export class EdaService implements ErrorContext {
    // Worksheet loc
    private worksheetLoc$ = new ReplaySubject<{
        projectKey: string,
        datasetSmartName: string,
        worksheetId?: string
    }>(1);

    // Force-refresh the list of worksheets
    private refreshListTrigger$ = new Subject<void>();

    // List of worksheets for the current dataset, or undefined if list not (yet) available
    private worksheetsIfAvailable$: Observable<WorksheetsService.WorksheetHead[] | undefined> =
        this.worksheetLoc$.pipe(
            switchMap(worksheetLoc => this.refreshListTrigger$.pipe(mapTo(worksheetLoc), startWith(worksheetLoc))),
            switchMap(worksheetLoc =>
                this.DataikuAPI.statistics.list(worksheetLoc.projectKey, worksheetLoc.datasetSmartName)
                    .pipe(catchAPIError(this), startWith(undefined))
            ),
            untilDestroyed(this),
            shareReplay(1)
        );

    private error$ = new BehaviorSubject<APIError | undefined>(undefined);

    constructor(
        private currentRoute: CurrentRouteService,
        private DataikuAPI: DataikuAPIService,
        @Inject('$state') private $state: fairAny,
        @Inject('StringUtils') private StringUtils: fairAny,
        @Inject('localStorageService') private localStorageService: fairAny
    ) {
        this.worksheetLoc$.pipe(
            filter(worksheetLoc => !worksheetLoc.worksheetId),
            switchMap(worksheetLoc => {
                return this.DataikuAPI.statistics
                    .list(worksheetLoc.projectKey, worksheetLoc.datasetSmartName)
                    .pipe(catchAPIError(this), map(worksheets => ({ worksheetLoc, worksheets })))
            }),
            untilDestroyed(this)
        ).subscribe(({ worksheetLoc, worksheets }) => {
            const projectKey = worksheetLoc.projectKey;
            const datasetName = worksheetLoc.datasetSmartName;

            // Pick lastest worksheet
            let worksheetId = this.localStorageService.get(this.worksheetLocalStorageKey(projectKey, datasetName)) || undefined;

            // Ignore it if it does not exist anymore
            if (worksheetId && !worksheets.map(ws => ws.id).includes(worksheetId)) {
                worksheetId = undefined;
            }
            // Pick first worksheet if there is one
            if (!worksheetId && worksheets.length > 0) {
                worksheetId = worksheets[0].id;
            }

            // Goto worksheet (empty placeholder page)
            this.changeWorksheetId(worksheetId);
        });
    }

    setWorksheetLoc(projectKey: string, datasetSmartName: string, worksheetId: string | undefined) {
        this.worksheetLoc$.next({ projectKey, datasetSmartName, worksheetId });
    }

    listWorksheets(): Observable<WorksheetsService.WorksheetHead[] | undefined> {
        return this.worksheetsIfAvailable$;
    }

    pushError(error?: APIError) {
        this.error$.next(error);
    }

    getError(): Observable<APIError | undefined> {
        return this.error$.pipe(distinctUntilChanged());
    }

    worksheetLocalStorageKey(projectKey: string, datasetName: string): string {
        return `${projectKey}.${datasetName}.currentWorksheet`;
    }

    computeNewStateForId(id: string | null | undefined): string {
        // four states are used:
        // projects.project.{datasets|foreigndatasets}.dataset.statistics(.worksheet)?
        // so, we always remove the final .worksheet and add it as needed
        const baseStateName = this.$state.current.name.replace(/\.worksheet$/, '');
        return id ? baseStateName + '.worksheet' : baseStateName;
    }

    changeWorksheetId(worksheetId: string | undefined) {
        const projectKey: string = this.currentRoute.projectKey;
        const datasetName: string = this.currentRoute.datasetName;
        const storageKey = this.worksheetLocalStorageKey(projectKey, datasetName);
        if (worksheetId) {
            this.localStorageService.set(storageKey, worksheetId);
        } else {
            this.localStorageService.remove(storageKey);
        }

        this.$state.go(this.computeNewStateForId(worksheetId),
            { projectKey, datasetName, worksheetId }, { location: true });
    }

    private createWorksheet(
        projectKey = this.currentRoute.projectKey,
        datasetSmartName = this.currentRoute.datasetName,
        rootCard?: WorksheetRootCard,
        name?: string,
    ) {
        return this.worksheetsIfAvailable$.pipe(
            first(),
            switchMap(worksheets => {
                if (!worksheets) {
                    return EMPTY;
                }
                name = this.StringUtils.transmogrify(name || 'Worksheet', worksheets.map(
                    ws => ws.name
                ));

                const dataSpec: DataSpec = {
                    inputDatasetSmartName: normalizeSmartName(projectKey, datasetSmartName),
                    // based on settings ?
                    autoRefreshSample: true,
                    refreshTrigger: 0,
                    datasetSelection: {
                        ...DEFAULT_DATASET_SELECTION_FIELDS,
                        samplingMethod: SamplingParam.SamplingMethod.HEAD_SEQUENTIAL,
                        maxRecords: 100000
                    },
                    containerSelection: {
                        containerMode: ContainerExecSelection.ContainerExecMode.INHERIT
                    }
                };

                if (!rootCard) {
                    rootCard = {
                        id: randomId(),
                        type: 'worksheet_root',
                        cards: [],
                        confidenceLevel: 0.95,
                        showConfidenceInterval: false
                    };
                }

                const worksheet: Worksheet = {
                    ...DEFAULT_TAGGABLE_OBJECT_FIELDS,
                    projectKey,
                    dataSpec,
                    rootCard,
                    name: name!
                };

                return this.DataikuAPI.statistics.save(worksheet)
                    .pipe(catchAPIError(this));
            })
        );
    }

    getDatasetFullInfo() {
        return this.worksheetLoc$.pipe(
            switchMap(({ projectKey, datasetSmartName }) => {
                const datasetLoc = resolveSmartName(projectKey, datasetSmartName);
                return this.DataikuAPI.datasets.getFullInfo(projectKey, datasetLoc.projectKey, datasetLoc.id)
            }));
    }

    newWorksheet() {
        this.createWorksheet().pipe(untilDestroyed(this)).subscribe(worksheet => {
            if (worksheet) {
                const worksheetId = worksheet.id;

                if (worksheetId) {
                    this.changeWorksheetId(worksheetId);
                }
            }
        });
    }

    // do we need to replace ids for each card?
    duplicateWorksheet(
        projectKey: string,
        datasetSmartName: string,
        rootCard: WorksheetRootCard,
        name: string
    ) {
        this.createWorksheet(projectKey, datasetSmartName, resetCardIds(rootCard), name)
            .pipe(untilDestroyed(this)).subscribe(worksheet => {
                this.$state.go(this.computeNewStateForId(worksheet.id), {
                    projectKey,
                    datasetName: datasetSmartName,
                    worksheetId: worksheet.id
                });
            });
    }

    deleteWorksheet(worksheet: Worksheet) {
        this.DataikuAPI.taggableObjects.delete([getWorksheetObjectRef(worksheet)], worksheet.projectKey)
            .pipe(catchAPIError(this), untilDestroyed(this))
            .subscribe(() => this.changeWorksheetId(undefined));
    }
}
