import { TaggableObjectsService } from '@model-main/server/services/taggable-objects-service';
import { Worksheet, ITaggingService, Insight, Card, AnyLoc, isAbstractCardContainer } from 'src/generated-sources';

export function getWorksheetObjectRef(ws: Worksheet): TaggableObjectsService.TaggableObjectRef {
    return { id: ws.id!, projectKey: ws.projectKey, type: ITaggingService.TaggableType.STATISTICS_WORKSHEET };
}

export function getInsightObjectRef(insight: Insight): TaggableObjectsService.TaggableObjectRef {
    return { id: insight.id!, projectKey: insight.projectKey, type: ITaggingService.TaggableType.INSIGHT };
}

export function hasConfidenceLevel(params: Card): params is Card & {
    showConfidenceInterval: boolean,
    confidenceLevel: number
} {
    return ('confidenceLevel' in params) && ('showConfidenceInterval' in params);
}

export function clearHighlightFilter(params: Card): Card {
    if (isAbstractCardContainer(params)) {
        params = {
            ...params,
            cards: params.cards.map(subParams => clearHighlightFilter(subParams))
        };
    }
    if ('highlightFilter' in params) {
        params = { ...params, highlightFilter: null };
    }
    return params;
}

export function resolveSmartName(contextProjectKey: string, datasetSmartName: string): AnyLoc {
    const chunks = datasetSmartName.split('.');
    if (chunks.length === 1) {
        return { projectKey: contextProjectKey, id: chunks[0] };
    } else if (chunks.length === 2) {
        return { projectKey: chunks[0], id: chunks[1] };
    }

    throw new Error('Invalid smart name: ' + datasetSmartName);
}

export function toSmartName(contextProjectKey: string, datasetLoc: AnyLoc): string {
    if (contextProjectKey === datasetLoc.projectKey) {
        return datasetLoc.id;
    } else {
        return `${datasetLoc.projectKey}.${datasetLoc.id}`;
    }
}

// A.B (A) -> B
// A.B (C) -> A.B
// B (A) -> A.B
export function normalizeSmartName(contextProjectKey: string, datasetSmartName: string): string {
    const loc = resolveSmartName(contextProjectKey, datasetSmartName);
    return toSmartName(contextProjectKey, loc);
}
