import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.bivariate.Pearson
 */
export interface Pearson extends Computation._BivariateComputation {
    // PolyJson type
    type: 'pearson';
}

export function isPearson(toTest: Computation): toTest is Pearson { return 'pearson' === toTest.type; }

export namespace Pearson {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.bivariate.Pearson$PearsonResult
     */
    export interface PearsonResult extends _AvailableResult {
        correlation: number;
        pvalue: number;

        // PolyJson type
        type: 'pearson';
    }

    export function isPearsonResult(toTest: ComputationResult): toTest is PearsonResult { return 'pearson' === toTest.type; }
}