import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.TTest1Samp
 */
export interface TTest1Samp extends Computation._UnivariateComputation {
    hypothesizedMean: number;

    // PolyJson type
    type: 'ttest_1samp';
}

export function isTTest1Samp(toTest: Computation): toTest is TTest1Samp { return 'ttest_1samp' === toTest.type; }

export namespace TTest1Samp {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.TTest1Samp$TTest1SampResult
     */
    export interface TTest1SampResult extends _AvailableResult {
        dof: number;
        pvalue: number;
        pvalueAltGt: number;
        pvalueAltLt: number;
        statistic: number;

        // PolyJson type
        type: 'ttest_1samp';
    }

    export function isTTest1SampResult(toTest: ComputationResult): toTest is TTest1SampResult { return 'ttest_1samp' === toTest.type; }
}