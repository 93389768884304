import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'niceConst'
})
export class NiceConstPipe implements PipeTransform {

    transform(input: string): string {
        if (!input || !input.length) {
            return '';
        }
        input = input.replace(/[\s_]+|([a-z])(?=[A-Z])/g, '$1 ').trim();
        let nice = input.charAt(0).toUpperCase() + input.substr(1).toLowerCase();
        if (nice == 'Lambda service') {
            nice = 'API service';
        }
        if (nice == 'Sql notebook') {
            nice = 'SQL notebook';
        }
        return nice;
    }

}
