import {BoxPlotFragment} from './fragments/box-plot-fragment';
import {CardResult, _CardResult} from './card-result';
import {Card} from './card';
import {_UnivariateCard} from './univariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.SignTest1SampCard
 */
export interface SignTest1SampCard extends _UnivariateCard {
    confidenceLevel: number;
    hypothesizedMedian: number;

    // PolyJson type
    type: 'sign_test_1samp';
}

export function isSignTest1SampCard(toTest: Card): toTest is SignTest1SampCard { return 'sign_test_1samp' === toTest.type; }

export namespace SignTest1SampCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.SignTest1SampCard$SignTest1SampCardResult
     */
    export interface SignTest1SampCardResult extends _CardResult {
        boxPlot: BoxPlotFragment;
        nbLarger: number;
        nbSmaller: number;
        pvalue: number;

        // PolyJson type
        type: 'sign_test_1samp';
    }

    export function isSignTest1SampCardResult(toTest: CardResult): toTest is SignTest1SampCardResult { return 'sign_test_1samp' === toTest.type; }
}