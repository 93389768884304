import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { SignTest1SampCard } from 'src/generated-sources';

@Component({
    selector: 'sign-test1-samp-card-help',
    templateUrl: './sign-test1-samp-card-help.component.html',
    styleUrls: ['./sign-test1-samp-card-help.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignTest1SampCardHelpComponent {
    @Input() params: SignTest1SampCard;
    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;
}
