import { Component, OnInit, Inject } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { resolveSmartName } from '@features/eda/utils';
import { catchAPIError, ErrorContext, APIError } from '@core/dataiku-api/api-error';

/**
 * Fake page which redirects to the real worksheet page from (projectKey, worksheetId)
 * Used to implement a ui-router state which works even without knowing dataset name
 */
@UntilDestroy()
@Component({
    selector: 'worksheet-redirection-page',
    templateUrl: './worksheet-redirection-page.component.html',
    styleUrls: ['./worksheet-redirection-page.component.less']
})
export class WorksheetRedirectionPageComponent implements OnInit, ErrorContext {
    error?: APIError;

    constructor(
        private DataikuAPI: DataikuAPIService,
        @Inject('$state') private $state: any
    ) { }

    ngOnInit() {
        this.DataikuAPI.statistics.get(this.$state.params.projectKey, this.$state.params.worksheetId)
            .pipe(catchAPIError(this), untilDestroyed(this))
            .subscribe(worksheet => {
                const datasetSmartName = worksheet.dataSpec.inputDatasetSmartName;
                const resolvedDatasetName = resolveSmartName(worksheet.projectKey, datasetSmartName);

                if (resolvedDatasetName.projectKey === worksheet.projectKey) {
                    this.$state.go('projects.project.datasets.dataset.statistics.worksheet', {
                        projectKey: worksheet.projectKey,
                        datasetName: resolvedDatasetName.id,
                        worksheetId: worksheet.id
                    }, { location: 'replace' });
                } else {
                    this.$state.go('projects.project.foreigndatasets.dataset.statistics.worksheet', {
                        projectKey: worksheet.projectKey,
                        datasetFullName: datasetSmartName,
                        worksheetId: worksheet.id
                    }, { location: 'replace' });
                }
            });
    }

    pushError(error: APIError): void {
        this.error = error;
    }
}
