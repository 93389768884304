import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { realAny } from 'dku-frontend-core';

@Component({
    selector: 'confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.less']
})
export class ConfirmModalComponent {

    constructor(
        public dialogRef: MatDialogRef<realAny, boolean>,
        @Inject(MAT_DIALOG_DATA) public data: {
            title: string,
            message: string,
            danger: boolean,
            icon?: string
        }
    ) { }

    confirm() {
        this.dialogRef.close(true);
    }

    cancel() {
        this.dialogRef.close(false);
    }

}
