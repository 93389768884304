import {
    Directive,
    Input,
    Output,
    HostListener,
    EventEmitter,
    EmbeddedViewRef,
    ViewContainerRef,
    OnDestroy,
    OnInit,
    ElementRef
} from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { DkuPopoverComponent } from './dku-popover/dku-popover.component';
import { Overlay, OverlayRef, OverlayConfig, ConnectedPosition } from '@angular/cdk/overlay';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
    selector: '[dkuPopoverTrigger]'
})
export class DkuPopoverTriggerDirective implements OnInit, OnDestroy {
    @Input('dkuPopoverTrigger') public dkuPopover: DkuPopoverComponent;
    @Input() public anchor: any;
    @Output() public hide: EventEmitter<any> = new EventEmitter<any>();

    private opened = false;
    private templatePortal: TemplatePortal;
    private viewRef: EmbeddedViewRef<any>;
    private overlayRef: OverlayRef;
    private preventCloseList = ['.bootstrap-select'];

    constructor(
        private elementRef: ElementRef,
        private viewContainerRef: ViewContainerRef,
        private overlay: Overlay
    ) {
    }

    @HostListener('click', ['$event'])
    onClick() {
        if (this.opened) {
            this.hidePopup();
        } else {
            this.showPopup();
        }
    }

    ngOnInit() {
        // If no anchor is specified as input, we set the current element as a default anchor
        if (!this.anchor) {
            this.anchor = this.elementRef.nativeElement;
        }

        this.preventCloseList.push('.' + this.dkuPopover.className);

        // Create a template portal
        this.templatePortal = new TemplatePortal(
            this.dkuPopover.popupTemplate,
            this.viewContainerRef
        );

        // if the popover was closed from within the dku popover,
        // the trigger directive needs to be notified in order
        // to properly dispose of the overlay.
        this.dkuPopover.notifyTrigger$.pipe(
            untilDestroyed(this)
        ).subscribe((hide) => {
            if (hide) {
                this.hidePopup();
            }
        });
    }

    ngOnDestroy() {
        if (this.opened) {
            this.hidePopup();
            // element stays on page during navigation...
            const popups = document.querySelectorAll('.' + this.dkuPopover.className);
            if (popups && popups.length) {
                popups.forEach(_ => _.remove());
            }
        }
    }

    showPopup() {
        const positions = [
            { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
            { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
            { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
            { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' }
        ] as ConnectedPosition[];

        const config = new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            positionStrategy: this.overlay.position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions(positions)
        });

        this.overlayRef = this.overlay.create(config);
        this.viewRef = this.overlayRef.attach(this.templatePortal);

        this.overlayRef.backdropClick().subscribe(() => {
            this.hidePopup();
        });

        this.dkuPopover.show();
        this.opened = true;
    }

    hidePopup() {
        this.overlayRef.dispose();
        this.dkuPopover.hide(false);
        this.opened = false;
        this.hide.emit();
    }
}
