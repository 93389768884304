import {Abstract2SampTestCard, _Abstract2SampTestCard} from './common/abstract2-samp-test-card';
import {CardResult} from './card-result';
import {Card} from './card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.TTest2SampCard
 */
export interface TTest2SampCard extends _Abstract2SampTestCard {
    // PolyJson type
    type: 'ttest_2samp';
}

export function isTTest2SampCard(toTest: Card): toTest is TTest2SampCard { return 'ttest_2samp' === toTest.type; }

export namespace TTest2SampCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.TTest2SampCard$TTest2SampCardResult
     */
    export interface TTest2SampCardResult extends Abstract2SampTestCard._Abstract2SampTestCardResult {
        pvalue: number;
        statistic: number;

        // PolyJson type
        type: 'ttest_2samp';
    }

    export function isTTest2SampCardResult(toTest: CardResult): toTest is TTest2SampCardResult { return 'ttest_2samp' === toTest.type; }
}