import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.EmpiricalCDF
 */
export interface EmpiricalCDF extends Computation._UnivariateComputation {
    // PolyJson type
    type: 'empirical_cdf';
}

export function isEmpiricalCDF(toTest: Computation): toTest is EmpiricalCDF { return 'empirical_cdf' === toTest.type; }

export namespace EmpiricalCDF {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.EmpiricalCDF$EmpiricalCDFResult
     */
    export interface EmpiricalCDFResult extends _AvailableResult {
        probs: number[];
        xvals: number[];

        // PolyJson type
        type: 'empirical_cdf';
    }

    export function isEmpiricalCDFResult(toTest: ComputationResult): toTest is EmpiricalCDFResult { return 'empirical_cdf' === toTest.type; }
}