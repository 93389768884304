import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Chi2IndTestCard } from 'src/generated-sources';

@Component({
    selector: 'chi2-ind-test-card-help',
    templateUrl: './chi2-ind-test-card-help.component.html',
    styleUrls: ['./chi2-ind-test-card-help.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class Chi2IndTestCardHelpComponent {
    @Input() params: Chi2IndTestCard;
    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;
}
