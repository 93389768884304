import {AbstractCardContainer, _AbstractCardContainer} from './abstract-card-container';
import {BivariateHeaderCard} from './../bivariate_header/bivariate-header-card';
import {CardResult} from './../card-result';
import {Card} from './../card';
import {UnivariateHeaderCard} from './../univariate_header/univariate-header-card';
import {Variable} from './../../models/variable';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.common.AbstractHeaderCard
 */
export interface _AbstractHeaderCard extends _AbstractCardContainer {
    xColumns: Variable[];
}

export type AbstractHeaderCard = BivariateHeaderCard | UnivariateHeaderCard;

export function isAbstractHeaderCard(toTest: Card): toTest is AbstractHeaderCard { return ["bivariate_header" , "univariate_header"].includes(toTest.type); }

export namespace AbstractHeaderCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.common.AbstractHeaderCard$AbstractHeaderCardResult
     */
    export interface _AbstractHeaderCardResult extends AbstractCardContainer._AbstractCardContainerResult {}

    export type AbstractHeaderCardResult = UnivariateHeaderCard.UnivariateHeaderCardResult | BivariateHeaderCard.BivariateHeaderCardResult;

    export function isAbstractHeaderCardResult(toTest: CardResult): toTest is AbstractHeaderCardResult { return ["univariate_header" , "bivariate_header"].includes(toTest.type); }
}