import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { CardResult, Card, Computation, ComputationResult } from 'src/generated-sources';
import { copyToClipboard } from '@utils/clipboard';

export interface DebugData {
    computation: Computation;
    params: Card;
    results: CardResult;
    computationResults: ComputationResult;
}

@Component({
    selector: 'debug-card-modal',
    templateUrl: './debug-card-modal.component.html',
    styleUrls: ['./debug-card-modal.component.less']
})
export class DebugCardModalComponent {
    computationJson: string;
    resultsJson: string;
    paramsJson: string;
    computationResultsJson: string;

    copyString: string;

    @ViewChild('tabs', { static: true}) tabs: MatTabGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: DebugData,
        public dialogRef: MatDialogRef<DebugCardModalComponent>
    ) {
        this.computationJson = JSON.stringify(data.computation, null, 4);
        this.paramsJson = JSON.stringify(data.params, null, 4);
        this.resultsJson = JSON.stringify(data.results, null, 4);
        this.computationResultsJson = JSON.stringify(data.computationResults, null, 4);

        this.copyString = this.computationJson;
    }

    copyToClipboard() {
        let text: string;
        switch (this.tabs.selectedIndex) {
            case 0:
                text = this.paramsJson;
                break;
            case 1:
                text = this.resultsJson;
                break;
            case 2:
                text = this.computationJson;
                break;
            case 3:
                text = this.computationResultsJson;
                break;
            default:
                throw new Error('Unhandled tab index');
        }
        copyToClipboard(text);
    }
}
