import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NavigatorService } from 'src/generated-sources';

@Injectable({providedIn: 'root'})
export class PrivateDatasetsAPI {
    constructor(private dkuHttp: DkuHttpService) {
    }

    get(projectKey: string, name: string, contextProjectKey?: string): Observable<any> {
        return this.dkuHttp.request('GET', '/datasets/get', { projectKey, name, contextProjectKey });
    }

    listPartitionsWithName(projectKey: string, datasetName: string): Observable<any> {
        return this.dkuHttp.request('GET', '/datasets/list-partitions-with-name', { projectKey, datasetName });
    }

    getFullInfo(contextProjectKey: string, projectKey: string, name: string): Observable<NavigatorService.DatasetFullInfo> {
        return this.dkuHttp.request('GET', '/datasets/get-full-info', { projectKey, name, contextProjectKey });
    }
}
