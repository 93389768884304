import {Distribution, _Distribution} from './distribution';

/**
 * Generated from com.dataiku.dip.eda.compute.distributions.Triangular
 */
export interface Triangular extends _Distribution {
    // PolyJson type
    type: 'triangular';
}

export function isTriangular(toTest: Distribution): toTest is Triangular { return 'triangular' === toTest.type; }

export namespace Triangular {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.Triangular$FittedTriangular
     */
    export interface FittedTriangular extends Distribution._FittedDistribution {
        a: number;
        b: number;
        c: number;

        // PolyJson type
        type: 'triangular';
    }

    export function isFittedTriangular(toTest: Distribution.FittedDistribution): toTest is FittedTriangular { return 'triangular' === toTest.type; }
}