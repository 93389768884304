import { Pipe, PipeTransform } from '@angular/core';
import { Filter, IntervalFilter, isAnumFilter, isAllFilter, isIntervalFilter, isAndFilter } from 'src/generated-sources';
import { smarterNumber } from '@shared/pipes/number-pipes/smarter-number.pipe';

function fullFilterName(filter: Filter): string {
    if (!filter) {
        return '';
    }
    if (filter.name != null && filter.name !== '') {
        return filter.name;
    }
    if (isAnumFilter(filter)) {
        return filter.values.map(value => value === '' ? '(no value)' : value).join(', ');
    }
    if (isAllFilter(filter)) {
        return 'All';
    }
    if (isIntervalFilter(filter)) {
        let beginning;
        let end;
        switch (filter.closed) {
            case IntervalFilter.ClosedMode.LEFT:
                beginning = '[';
                end = ')';
                break;
            case IntervalFilter.ClosedMode.RIGHT:
                beginning = '(';
                end = ']';
                break;
            case IntervalFilter.ClosedMode.BOTH:
                beginning = '[';
                end = ']';
                break;
            case IntervalFilter.ClosedMode.NEITHER:
                beginning = '(';
                end = ')';
        }
        const left = filter.left === -Number.MAX_VALUE ? '-∞' : smarterNumber(filter.left);
        const right = filter.right === Number.MAX_VALUE ? '+∞' : smarterNumber(filter.right);
        return `${beginning}${left},${right}${end}`;
    }
    if (isAndFilter(filter)) {
        return filter.filters
            .map(childFilter => filterName(childFilter))
            .join(', ');
    }

    // NOT IMPLEMENTED
    return 'UNNAMED';
}

export function filterName(filter: Filter): string {
    return fullFilterName(filter).slice(0, 100);
}

@Pipe({
    name: 'filterName',
    pure: true
})
export class FilterNamePipe implements PipeTransform {
    transform(filter: Filter): string {
        return filterName(filter);
    }
}
