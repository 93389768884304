import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.Min
 */
export interface Min extends Computation._UnivariateComputation {
    // PolyJson type
    type: 'min';
}

export function isMin(toTest: Computation): toTest is Min { return 'min' === toTest.type; }

export namespace Min {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.Min$MinResult
     */
    export interface MinResult extends _AvailableResult {
        value: number;

        // PolyJson type
        type: 'min';
    }

    export function isMinResult(toTest: ComputationResult): toTest is MinResult { return 'min' === toTest.type; }
}