import { ElementRef, Injector, Directive, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
    selector: 'angularjs-dataset-selector'
})
export class AngularjsDatasetSelectorDirective extends UpgradeComponent {
    @Input() dataset: any;
    @Input() datasetChange: any;
    @Input() availableDatasets: any;
    @Input() required?: boolean;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('ng1DatasetSelectorDirective', elementRef, injector);
    }
}