import {Distribution2D, _Distribution2D} from './distribution2-d';

/**
 * Generated from com.dataiku.dip.eda.compute.distributions.KDE2D
 */
export interface KDE2D extends _Distribution2D {
    x_relative_bandwidth: number;
    y_relative_bandwidth: number;

    // PolyJson type
    type: 'kde_2d';
}

export function isKDE2D(toTest: Distribution2D): toTest is KDE2D { return 'kde_2d' === toTest.type; }

export namespace KDE2D {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.KDE2D$FittedKDE2D
     */
    export interface FittedKDE2D extends Distribution2D._FittedDistribution2D {
        // PolyJson type
        type: 'kde_2d';
    }

    export function isFittedKDE2D(toTest: Distribution2D.FittedDistribution2D): toTest is FittedKDE2D { return 'kde_2d' === toTest.type; }
}