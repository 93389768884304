import {Distribution, _Distribution} from './distribution';

/**
 * Generated from com.dataiku.dip.eda.compute.distributions.Normal
 */
export interface Normal extends _Distribution {
    // PolyJson type
    type: 'normal';
}

export function isNormal(toTest: Distribution): toTest is Normal { return 'normal' === toTest.type; }

export namespace Normal {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.Normal$FittedNormal
     */
    export interface FittedNormal extends Distribution._FittedDistribution {
        mean: number;
        std: number;

        // PolyJson type
        type: 'normal';
    }

    export function isFittedNormal(toTest: Distribution.FittedDistribution): toTest is FittedNormal { return 'normal' === toTest.type; }
}