import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartLogTailComponent } from './components/smart-log-tail/smart-log-tail.component';
import { ErrorFixability } from './components/error-fixability/error-fixability.component';
import { APIErrorAlertComponent } from './components/api-error-alert/api-error-alert.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ErrorFixability,
        SmartLogTailComponent,
        APIErrorAlertComponent
    ],
    exports: [
        ErrorFixability,
        SmartLogTailComponent,
        APIErrorAlertComponent
    ]
})
export class DataikuAPIModule { }
