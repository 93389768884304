<form [formGroup]="configForm" id="config-form" class="dkuform-modal-horizontal">
    <div class="control-group">
        <label class="control-label">
            Variable
        </label>
        <single-variable-selector [variables]="allVariables$ | async" formControlName="column"></single-variable-selector>
    </div>
    <div class="control-group">
        <label class="control-label">Hypothesized mean</label>
        <input type="number" formControlName="hypothesizedMean">
    </div>    
</form>