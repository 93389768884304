import {Distribution, _Distribution} from './distribution';

/**
 * Generated from com.dataiku.dip.eda.compute.distributions.NormalMixture
 */
export interface NormalMixture extends _Distribution {
    nbComponents: number;

    // PolyJson type
    type: 'normal_mixture';
}

export function isNormalMixture(toTest: Distribution): toTest is NormalMixture { return 'normal_mixture' === toTest.type; }

export namespace NormalMixture {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.NormalMixture$FittedNormalMixture
     */
    export interface FittedNormalMixture extends Distribution._FittedDistribution {
        components: NormalMixture.FittedNormalMixture.Component[];

        // PolyJson type
        type: 'normal_mixture';
    }

    export function isFittedNormalMixture(toTest: Distribution.FittedDistribution): toTest is FittedNormalMixture { return 'normal_mixture' === toTest.type; }
}

export namespace NormalMixture.FittedNormalMixture {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.NormalMixture$FittedNormalMixture$Component
     */
    export interface Component {
        mean: number;
        std: number;
        weight: number;
    }
}