import {BivariateBoxPlotCard} from './bivariate_header/bivariate-box-plot-card';
import {BivariateFrequencyTableCard} from './bivariate_header/bivariate-frequency-table-card';
import {BivariateHistogramCard} from './bivariate_header/bivariate-histogram-card';
import {BivariateSummaryStatsCard} from './bivariate_header/bivariate-summary-stats-card';
import {Card, _Card} from './card';
import {Chi2IndTestCard} from './chi2-ind-test-card';
import {Fit2DDistributionCard} from './fit2-ddistribution-card';
import {FitCurveCard} from './fit-curve-card';
import {KsTest2SampCard} from './ks-test2-samp-card';
import {MoodTest2SampCard} from './mood-test2-samp-card';
import {MoodTestNSampCard} from './mood-test-nsamp-card';
import {MosaicPlotCard} from './bivariate_header/mosaic-plot-card';
import {OneWayANOVACard} from './one-way-anovacard';
import {PairwiseMoodTestCard} from './pairwise-mood-test-card';
import {PairwiseTTestCard} from './pairwise-ttest-card';
import {ScatterPlotCard} from './bivariate_header/scatter-plot-card';
import {TTest2SampCard} from './ttest2-samp-card';
import {Variable} from './../models/variable';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.BivariateCard
 */
export interface _BivariateCard extends _Card {
    xColumn: Variable;
    yColumn: Variable;
}

export type BivariateCard = MosaicPlotCard | Chi2IndTestCard | Fit2DDistributionCard | PairwiseMoodTestCard | MoodTestNSampCard | ScatterPlotCard | BivariateSummaryStatsCard | BivariateFrequencyTableCard | OneWayANOVACard | PairwiseTTestCard | TTest2SampCard | MoodTest2SampCard | BivariateHistogramCard | KsTest2SampCard | BivariateBoxPlotCard | FitCurveCard;

export function isBivariateCard(toTest: Card): toTest is BivariateCard { return ["mosaic_plot" , "chi2_independence_test" , "fit_2d_distribution" , "pairwise_mood" , "mood_nsamp" , "scatter_plot" , "bivariate_summary" , "bivariate_frequency_table" , "oneway_anova" , "pairwise_ttest" , "ttest_2samp" , "mood_test_2samp" , "bivariate_histogram" , "ks_test_2samp" , "bivariate_box_plot" , "fit_curve"].includes(toTest.type); }