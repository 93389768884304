<ng-container *ngIf="showMenu">
    <button
        *ngIf="showExtendedMenu"
        class="btn btn--icon btn--secondary menu-button pull-right"
        [class.menu-button--active]="menuActive"
        (menuOpened)="menuActive = true"
        (menuClosed)="menuActive = false"
        [matMenuTriggerFor]="contextualMenu"
        [matMenuTriggerRestoreFocus]="false"
        [qa]="{'eda-card-menu-trigger': { id: params.id, type: params.type}}"
    >
        <i class="icon-dku-ellipsis-vertical"></i>
    </button>

    <button
        *ngIf="extendedActions && deletable"
        class="btn btn--icon btn--secondary menu-button pull-right"
        [class.menu-button--active]="menuActive"
        title="{{ removalText }}"
        [qa]="{'eda-card-menu-item': {action: 'delete'}}"
        [wt1Click]="'worksheets_delete-card'"
        (click)="handleDelete()"
    >
        <i class="icon-trash"></i>
    </button>

    <button
        *ngIf="hasConfigureCardModal"
        class="btn btn--icon btn--secondary menu-button pull-right"
        [class.menu-button--active]="menuActive"
        title="{{ params | cardTitle: 'config' }}"
        [qa]="{'eda-card-menu-item': {action: 'configure'}}"
        (click)="configureCard()"
    >
        <i class="icon-pencil"></i>
    </button>

    <mat-menu #contextualMenu>
        <ng-template matMenuContent>
            <button
                mat-menu-item
                *ngIf="hasConfigureVisualizationModal"
                (click)="configureVisualization()"
                [qa]="{'eda-card-menu-item': {action: 'configure-viz'}}"
            >
                Configure visualization…
            </button>
            <ng-container *ngIf="this.params.type == 'column_card'">
                <button
                    mat-menu-item
                    *ngIf="params.column.type != Variable.Type.CONTINUOUS && extendedActions"
                    (click)="treatAs(Variable.Type.CONTINUOUS)"
                    [wt1Click]="'worksheets_treat-as'"
                    [qa]="{'eda-card-menu-item': {action: 'treat-as', as: 'numerical'}}"
                >
                    Treat {{ params.column.name }} as continuous
                </button>
                <button
                    mat-menu-item
                    *ngIf="params.column.type != Variable.Type.CATEGORICAL && extendedActions"
                    (click)="treatAs(Variable.Type.CATEGORICAL)"
                    [wt1Click]="'worksheets_treat-as'"
                    [qa]="{'eda-card-menu-item': {action: 'treat-as', as: 'categorical'}}"
                >
                    Treat {{ params.column.name }} as categorical
                </button>
            </ng-container>
            <button
                mat-menu-item
                *ngIf="extendedActions"
                (click)="addCard()"
                [wt1Click]="'worksheets_duplicate-card'"
                [qa]="{'eda-card-menu-item': {action: 'duplicate'}}"
            >
                Duplicate as new card
            </button>
            <button
                mat-menu-item
                *ngIf="extendedActions"
                (click)="debugCard()"
                [qa]="{'eda-card-menu-item': {action: 'view-json'}}"
            >
                View JSON…
            </button>
            <button
                mat-menu-item
                *ngIf="extendedActions"
                [qa]="{'eda-card-menu-item': {action: 'publish'}}"
                [wt1Click]="'worksheets_publish-card'"
                (click)="publish()"
            >
                Publish…
            </button>
        </ng-template>
    </mat-menu>
</ng-container>
