import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { SignTest1SampCard } from 'src/generated-sources';

@Component({
    selector: 'sign-test1-samp-card-body',
    templateUrl: './sign-test1-samp-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/test-conclusion.less',
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/card-layout.less',
        './sign-test1-samp-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignTest1SampCardBodyComponent {
    @Input() params: SignTest1SampCard;
    @Input() results: SignTest1SampCard.SignTest1SampCardResult;

    get isRejected() {
        return this.results.pvalue <= (1 - this.params.confidenceLevel);
    }
}
