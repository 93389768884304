import {CardResult, _CardResult} from './../card-result';
import {Card} from './../card';
import {Filter} from './../../../compute/filtering/filter';
import {_Abstract2DPivotCard} from './abstract2-dpivot-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.MosaicPlotCard
 */
export interface MosaicPlotCard extends _Abstract2DPivotCard {
    highlightFilter?: Filter | null;

    // PolyJson type
    type: 'mosaic_plot';
}

export function isMosaicPlotCard(toTest: Card): toTest is MosaicPlotCard { return 'mosaic_plot' === toTest.type; }

export namespace MosaicPlotCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.MosaicPlotCard$MosaicPlotData
     */
    export interface MosaicPlotData {
        counts: number[];
        highlightedYPercentages?: number[] | null;
        totalCount: number;
        xPercentages: number[];
        xVals: Filter[];
        yPercentages: number[];
        yVals: Filter[];
    }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.MosaicPlotCard$MosaicPlotCardResult
     */
    export interface MosaicPlotCardResult extends _CardResult {
        mosaic: MosaicPlotCard.MosaicPlotData;

        // PolyJson type
        type: 'mosaic_plot';
    }

    export function isMosaicPlotCardResult(toTest: CardResult): toTest is MosaicPlotCardResult { return 'mosaic_plot' === toTest.type; }
}