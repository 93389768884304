import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.multivariate.FetchValues
 */
export interface FetchValues extends Computation._MultivariateComputation {
    // PolyJson type
    type: 'fetch_values';
}

export function isFetchValues(toTest: Computation): toTest is FetchValues { return 'fetch_values' === toTest.type; }

export namespace FetchValues {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.multivariate.FetchValues$FetchValuesResult
     */
    export interface FetchValuesResult extends _AvailableResult {
        series: number[][];

        // PolyJson type
        type: 'fetch_values';
    }

    export function isFetchValuesResult(toTest: ComputationResult): toTest is FetchValuesResult { return 'fetch_values' === toTest.type; }
}