import {Grouping, _Grouping} from './grouping';
import {GroupingResult, _GroupingResult} from './grouping-result';

/**
 * Generated from com.dataiku.dip.eda.compute.grouping.CrossGrouping
 */
export interface CrossGrouping extends _Grouping {
    groupings: Grouping[];

    // PolyJson type
    type: 'cross';
}

export function isCrossGrouping(toTest: Grouping): toTest is CrossGrouping { return 'cross' === toTest.type; }

export namespace CrossGrouping {
    /**
     * Generated from com.dataiku.dip.eda.compute.grouping.CrossGrouping$CrossGroupingResult
     */
    export interface CrossGroupingResult extends _GroupingResult {
        groups: GroupingResult[];

        // PolyJson type
        type: 'cross';
    }

    export function isCrossGroupingResult(toTest: GroupingResult): toTest is CrossGroupingResult { return 'cross' === toTest.type; }
}