import {IsotonicCurve} from './isotonic-curve';
import {PolynomialCurve} from './polynomial-curve';

/**
 * Generated from com.dataiku.dip.eda.compute.curves.Curve
 */
export interface _Curve {}

export type Curve = IsotonicCurve | PolynomialCurve;

export namespace Curve {
    /**
     * Generated from com.dataiku.dip.eda.compute.curves.Curve$ParametrizedCurve
     */
    export interface _ParametrizedCurve {}

    export type ParametrizedCurve = IsotonicCurve.ParametrizedIsotonicCurve | PolynomialCurve.ParametrizedPolynomialCurve;

    export function isParametrizedCurve(toTest: Curve.ParametrizedCurve): toTest is ParametrizedCurve { return ["isotonic" , "polynomial"].includes(toTest.type); }
}