import { BrowserModule } from '@angular/platform-browser';
import { DoBootstrap, NgModule, APP_INITIALIZER } from '@angular/core';
import { upgradedProviders } from '@migration/upgraded-providers';
import { DkuActivatedRouteService } from '@migration/dku-activated-route';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { KeyValuesListModule } from './widgets/lists/key-values-list/key-values-list.module';
import { ValuesListModule } from './widgets/lists/values-list/values-list.module';
import { CredentialsListModule } from './widgets/lists/credentials-list/credentials-list.module';
import { ConnectionPropertiesListModule } from './widgets/lists/connection-properties-list/connection-properties-list.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalsModule } from '@shared/modals/modals.module';
import { CoreModule } from '@core/core.module';
import { EdaModule } from '@features/eda/eda.module';

import { EdaComponent } from '@features/eda/eda.component';
import { InsightCardComponent } from '@features/eda/worksheet/cards/insight-card/insight-card.component';
import { DataikuWT1Module } from '@core/dataiku-wt1/dataiku-wt1.module';
import { QaSelectorComponent } from './shared/directives/qa-selector/qa-selector.component';
import { QaModeService } from '@shared/directives/qa-mode/qa-mode.service';
import { QaSelectorService } from '@shared/directives/qa-selector/qa-selector.service';
import { DkuHttpService } from 'dku-frontend-core';
import { DkuLegacyHttpService } from '@core/requests/dku-legacy-http.service';


@NgModule({
    imports: [
        BrowserModule,
        WidgetsModule,
        BrowserAnimationsModule,
        MatDialogModule,
        // Core module (singleton services)
        CoreModule,
        ModalsModule,
        EdaModule,
        KeyValuesListModule,
        ValuesListModule,
        CredentialsListModule,
        ConnectionPropertiesListModule,
        DataikuWT1Module.legacy()
    ],
    entryComponents: [
        EdaComponent,
        InsightCardComponent,
        QaSelectorComponent
    ],
    providers: [
        ...upgradedProviders(),
        QaModeService,
        QaSelectorService,
        DkuActivatedRouteService, // Helper to prepare the transition to Angular2+ router when the migration is full
        { provide: '$scope', useExisting: '$rootScope' }, // Added to make upgraded angularjs directive work in modals... don't ask.
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [QaModeService, QaSelectorService],
            multi: true
        },
        { provide: DkuHttpService, useClass: DkuLegacyHttpService }
    ],
    bootstrap: [],
    declarations: [QaSelectorComponent],
})
export class AppModule implements DoBootstrap {
    ngDoBootstrap() {
    }
}
