import { Observable } from 'rxjs';
import { DkuHttpService } from 'dku-frontend-core';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class PrivateTaggableObjectsAPI {
    constructor(private dkuHttp: DkuHttpService) { }

    delete(request: any[], contextProjectKey: string): Observable<any> {
        return this.dkuHttp.request('GET', '/taggable-objects/delete', {
            request,
            contextProjectKey,
        });
    }
}
