<form
[formGroup]="form"
>

<fieldset class="control-group">
    <label for="" class="control-label">Use partitions</label>
    <div class="controls">
        <dku-bs-select-form-control
            formControlName="partitionSelectionMethod"
            ngOptions="v.key as v.value for v in list"
            [list]="preparedPartitionSelectionMethods"
            [optionsDescriptions]="SamplingData.partitionSelectionMethodsDesc"
            [layout]="'list'"
        >
        </dku-bs-select-form-control>
    </div>
</fieldset>
<fieldset *ngIf="form.value.partitionSelectionMethod == 'SELECTED'" class="control-group">
    <span class="control-label">Partitions</span>
    <div class="controls">
        <span *ngIf="partitionsList == null || partitionsList!.length == 0">
            <input type="text" formControlName="selectedPartitions" commaSeparatedView style="display:inline-block; width: 176px"/>
            <button *ngIf="partitionsList === null" class="btn btn--secondary" (click)="loadPartitionsList()">
                <i class="icon-list" style="cursor:pointer" toggle="tooltip" title="Retrieve list" data-placement="bottom"></i>
            </button>
        </span>
        <span class="help-inline" *ngIf="partitionsList == null || partitionsList!.length == 0">Comma-separated list</span>
        <span class="help-inline" *ngIf="partitionsList != null && partitionsList!.length == 0">(No partition built)</span>

        <span *ngIf="partitionsList != null && partitionsList!.length > 0 ">
            <dku-bs-select-form-control formControlName="selectedPartitions" ngOptions="part for part in list"
                [list]="partitionsList" dataActionsBox="true" dataLiveSearch="true" dkuMultiple="multiple"
                [layout]="'list'"
            >
            </dku-bs-select-form-control>
        </span>
    </div>
</fieldset>
<fieldset *ngIf="form.value.partitionSelectionMethod == 'LATEST_N'" class="control-group">
    <label class="control-label">Nb. partitions</label>
    <div class="controls">
        <input type="number" forceInteger min="1" max="100000000" formControlName="latestPartitionsN"/>
    </div>
</fieldset>

</form>