import {BoxPlotFragment} from './fragments/box-plot-fragment';
import {CardResult, _CardResult} from './card-result';
import {Card} from './card';
import {_UnivariateCard} from './univariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.TZTest1SampCard
 */
export interface TZTest1SampCard extends _UnivariateCard {
    confidenceLevel: number;
    hypothesizedMean: number;
    knownStdDev?: number | null;

    // PolyJson type
    type: 'ttest_ztest_1samp';
}

export function isTZTest1SampCard(toTest: Card): toTest is TZTest1SampCard { return 'ttest_ztest_1samp' === toTest.type; }

export namespace TZTest1SampCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.TZTest1SampCard$TZTest1SampCardResult
     */
    export interface TZTest1SampCardResult extends _CardResult {
        boxPlot: BoxPlotFragment;
        dof?: number | null;
        pvalue: number;
        statistic: number;

        // PolyJson type
        type: 'ttest_ztest_1samp';
    }

    export function isTZTest1SampCardResult(toTest: CardResult): toTest is TZTest1SampCardResult { return 'ttest_ztest_1samp' === toTest.type; }
}