import { Injectable } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { ProjectsService as ProjectsServiceDTO } from 'src/generated-sources';
import { switchMap, shareReplay } from 'rxjs/operators';
import { of } from 'rxjs';

export interface ProjectSummary {
    object: ProjectsServiceDTO.UIProject;
    objectsCounts: any | undefined;
    timeline: any | undefined;
    interest: any;
}

@Injectable({
    providedIn: 'root'
})
export class ProjectsService {
    public currentProjectSummary$ = this.currentRoute.projectKey$.pipe(
        switchMap(projectKey => projectKey ?
            this.DataikuAPI.projects.getSummary(projectKey)
            : of(undefined)
        ),
        shareReplay(1)
    );

    constructor(
        private currentRoute: CurrentRouteService,
        private DataikuAPI: DataikuAPIService
    ) { }
}
