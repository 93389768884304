import { Worksheet } from '@model-main/eda/worksheets/models/worksheet';
import { Observable } from 'rxjs';
import { Card, Computation, DataSpec, TaggableObjectsService, ComputationResult, WorksheetsService, CardResult, InteractiveQuery } from 'src/generated-sources';
import { Sample } from '@model-main/eda/compute/sampling/sample';
import { TypedFutureResponse, FutureWatcherService, Filter } from 'dku-frontend-core';
import { DkuHttpService } from 'dku-frontend-core';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PrivateStatisticsAPI {
    constructor(
        private dkuHttp: DkuHttpService,
        private futureWatcher: FutureWatcherService
    ) { }

    list(projectKey: string, datasetSmartName: string): Observable<WorksheetsService.WorksheetHead[]> {
        return this.dkuHttp.request('GET', '/eda/worksheets/list-worksheets', { projectKey, datasetSmartName });
    }

    get(projectKey: string, worksheetId: string): Observable<Worksheet> {
        return this.dkuHttp.request('GET', '/eda/worksheets/get-worksheet', { projectKey, worksheetId });
    }

    save(worksheet: Worksheet): Observable<Worksheet> {
        return this.dkuHttp.request('POST', '/eda/worksheets/save-worksheet', { worksheet });
    }

    currentSample(key: TaggableObjectsService.TaggableObjectRef, dataSpec: DataSpec): Observable<Sample | null> {
        return this.dkuHttp.request('POST', '/eda/compute/samples/current', { key, dataSpec, });
    }

    rebuildSample(key: TaggableObjectsService.TaggableObjectRef, dataSpec: DataSpec): Observable<TypedFutureResponse<Sample>> {
        return this.futureWatcher.requestFuture('POST', '/eda/compute/samples/rebuild', { key, dataSpec });
    }

    getComputationPlan(card: Card): Observable<Computation> {
        return this.dkuHttp.request('POST', '/eda/card-compute/computation-card-plan', { card });
    }

    getComputationResults(card: Card, sampleId: string): Observable<ComputationResult> {
        return this.dkuHttp.request('POST', '/eda/card-compute/computation-card-result', { card, sampleId });
    }

    fixCard(card: Card): Observable<Card> {
        return this.dkuHttp.request('POST', '/eda/card-compute/fix-card', { card });
    }

    computeResults(card: Card, cacheOnly: boolean, sampleId: string): Observable<TypedFutureResponse<CardResult>> {
        return this.futureWatcher.requestFuture('POST', '/eda/card-compute/compute-card-result', { card, cacheOnly, sampleId });
    }

    runInteractiveQuery<T extends InteractiveQuery>(query: T, sampleId: string): Observable<TypedFutureResponse<Filter<InteractiveQuery.InteractiveQueryResult, { type: T['type']}>>> {
        return this.futureWatcher.requestFuture('POST', '/eda/card-compute/run-interactive-query', { query, sampleId }, false);
    }
}
