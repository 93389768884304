<div class="vertical-flex" *ngIf="columnCollapseStates$ | async as columnCollapseStates"
    cdkDropList [cdkDropListDisabled]="readOnly" cdkDropListOrientation="vertical"
    (cdkDropListDropped)="dropRow($event)">
    <ng-container *ngIf="groupCollapseStates$ | async as groupCollapseStates">
        <ng-container *ngFor="let columnParams of params.cards; let columnIndex = index; trackBy: trackByCardId">
            <div
                cdkDrag
                class="column-block vertical-flex" [class.column-block--collapsed]="columnCollapseStates[columnIndex]"
                [qa]="{
                    'eda-column-card': {
                        'id': columnParams.id,
                        'column-name': columnParams.column.name
                    }
                }">
                <ng-container *ngIf="!columnCollapseStates[columnIndex]; else dragcollapsed">
                    <div class="drag-drop-placeholder" *cdkDragPlaceholder></div>
                </ng-container>
                <ng-template #dragcollapsed>
                    <div class="drag-drop-placeholder drag-drop-placeholder--collapsed class.column-block--collapsed" *cdkDragPlaceholder></div>
                </ng-template>
                <div class="column-block__title" cdkDragHandle>
                    <card-menu class="pull-right" [params]="columnParams" [readOnly]="readOnly" [deletable]="true"
                        [extendedActions]="extendedActions" (action)="handleColumnCardAction($event, columnIndex)">
                    </card-menu>
                    <column-title (click)="toggleColumn(columnParams.column, !columnCollapseStates[columnIndex])"
                        [column]="columnParams.column" [response]="isBivariateHeaderCard(params) ? params['yColumn'] : ''"
                        [collapsed]="columnCollapseStates[columnIndex]" [splitBy]="params.splitBy"
                        [showCollapsingControls]="showCollapsingControls"></column-title>
                </div>

                <lazy-render [visibleTemplate]="visible" [trackData]="columnParams"
                    [passThrough]="params.cards.length < 10">
                    <ng-template #visible>

                        <div [@fadeInOut]="columnCollapseStates[columnIndex] ? 'closed' : 'opened'">
                            <div class="horizontal-flex" *ngIf="!columnCollapseStates[columnIndex] | delayWhenFalse">
                                <div class="group-block"
                                    [class.group-block--collapsed]="groupCollapseStates[groupIndex]"
                                    [class.group-block--column-wide]="params.type === 'bivariate_header'"
                                    *ngFor="let groupResult of results.results; let groupIndex = index; trackBy: trackByIndex"
                                    [qa]="{'eda-group': {name: results.groups[groupIndex] | filterName}}">
                                    <div class="group-block__title"
                                        [class.group-block__title--collapsed]="groupCollapseStates[groupIndex]"
                                        (click)="toggleGroup(results.groups[groupIndex], !groupCollapseStates[groupIndex])">
                                        <div class="group-block__title-content"
                                            [class.group-block__title-content--collapsed]="groupCollapseStates[groupIndex]">
                                            <div class="group-block__title-content-chevron">
                                                <i *ngIf="showCollapsingControls"
                                                    [class.icon-chevron-right]="groupCollapseStates[groupIndex]"
                                                    [class.icon-chevron-down]="!groupCollapseStates[groupIndex]"></i>
                                            </div>

                                            <div class="group-block__title-content-label">
                                                {{ results.groups[groupIndex] | filterName }}
                                            </div>

                                            <ng-container *ngIf="!groupCollapseStates[groupIndex]">
                                                <button class="btn btn--icon btn--secondary menu-button pull-right"
                                                    [matMenuTriggerFor]="contextualMenu"
                                                    *ngIf="!readOnly && extendedActions"
                                                    (click.stop)="false">
                                                    <i class="icon-dku-ellipsis-vertical"></i>
                                                </button>

                                                <mat-menu #contextualMenu>
                                                    <ng-template matMenuContent>
                                                        <button mat-menu-item
                                                            (click)="exportGroup(columnIndex, groupIndex, CardActionType.ADD)"
                                                            [wt1Click]="'worksheets_duplicate-grouped-header-card'">
                                                            Duplicate as new card
                                                        </button>
                                                        <button mat-menu-item
                                                            (click)="exportGroup(columnIndex, groupIndex, CardActionType.DEBUG)">
                                                            View JSON…
                                                        </button>
                                                        <button mat-menu-item
                                                            (click)="exportGroup(columnIndex, groupIndex, CardActionType.PUBLISH)"
                                                            [wt1Click]="'worksheets_publish-grouped-header-card'">
                                                            Publish…
                                                        </button>
                                                    </ng-template>
                                                </mat-menu>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <column-card-body *ngIf="!groupCollapseStates[groupIndex]" class="card--column-card"
                                        [params]="columnParams" [headerParams]="params"
                                        [results]="groupResult.results[columnIndex]" [readOnly]="readOnly"
                                        [extendedActions]="extendedActions" (headerAction)="action.emit($event)"
                                        (action)="handleColumnCardAction($event, columnIndex, groupIndex)">
                                    </column-card-body>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </lazy-render>
            </div>

        </ng-container>
            <div *ngIf="!readOnly" class="card--placeholder" (click)="configureAnalysis()">
                + Add another variable
            </div>
    </ng-container>
</div>