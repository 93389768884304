<editable-list 
    [itemsFormArray]="itemsFormArray"
    [addLabel]="addLabel" 
    [sortable]="sortable"
    (onAdd)="onAdd.emit($event)" 
    (onDelete)="onDelete.emit($event)"
    (onValidityChange)="onValidityChange.emit($event)">
    <ng-template let-item="item" let-goToNext="goToNext">
        <editable-list-template (onInputEnter)="goToNext($event)">
            <editable-list-input 
                [inputControl]="item?.get([keyIdentifier])"
                placeholder="{{keyPlaceholder}}" 
                [required]="keyRequired">
            </editable-list-input>
            <editable-list-arrow></editable-list-arrow>
            <editable-list-input
                [inputControl]="item?.get(valueIdentifier)"
                placeholder="{{valuePlaceholder}}"
                [required]="valueRequired">
            </editable-list-input>
        </editable-list-template>
    </ng-template>
</editable-list>
