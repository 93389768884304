import { Component, Input, EventEmitter, Output, forwardRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'dataset-selector-form-control',
    template: `
        <dataset-selector
            [dataset]="dataset"
            (datasetChange)="select($event)"
            [availableDatasets]="availableDatasets"
            [required]="required"
        ></dataset-selector>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DatasetSelectorFormControlComponent),
        multi: true
    }]
})
export class DatasetSelectorFormControlComponent implements ControlValueAccessor {
    @Input() dataset: string;
    @Input() availableDatasets: any[];
    @Input() required?: boolean;
    @Output() datasetChange: EventEmitter<String> = new EventEmitter<String>();

    propagateChange = (_: any) => {};

    constructor(
        private changeDetectionRef: ChangeDetectorRef,
    ) {

    }

    select(selected: any) {
        this.propagateChange(selected);
    }

    writeValue(value: any) {
        this.dataset = value;
    }

    registerOnChange(fn: (_: any) => {}) {
        this.propagateChange = fn;
    }

    registerOnTouched() {}
}
