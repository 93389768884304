import {CardResult, _CardResult} from './../card-result';
import {Card} from './../card';
import {Quantiles} from './../../../compute/computations/univariate/quantiles';
import {_UnivariateCard} from './../univariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.univariate_header.QuantilesTableCard
 */
export interface QuantilesTableCard extends _UnivariateCard {
    confidenceLevel: number;
    customFreqs?: number[] | null;
    showConfidenceInterval: boolean;

    // PolyJson type
    type: 'quantile_table';
}

export function isQuantilesTableCard(toTest: Card): toTest is QuantilesTableCard { return 'quantile_table' === toTest.type; }

export namespace QuantilesTableCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.univariate_header.QuantilesTableCard$QuantilesTableCardResult
     */
    export interface QuantilesTableCardResult extends _CardResult {
        quantiles: Quantiles.QuantileDesc[];

        // PolyJson type
        type: 'quantile_table';
    }

    export function isQuantilesTableCardResult(toTest: CardResult): toTest is QuantilesTableCardResult { return 'quantile_table' === toTest.type; }
}