import {AbstractNSampTestCard} from './common/abstract-nsamp-test-card';
import {CardResult} from './card-result';
import {Card} from './card';
import {_AbstractPairwiseTestCard} from './common/abstract-pairwise-test-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.PairwiseMoodTestCard
 */
export interface PairwiseMoodTestCard extends _AbstractPairwiseTestCard {
    // PolyJson type
    type: 'pairwise_mood';
}

export function isPairwiseMoodTestCard(toTest: Card): toTest is PairwiseMoodTestCard { return 'pairwise_mood' === toTest.type; }

export namespace PairwiseMoodTestCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.PairwiseMoodTestCard$PairwiseMoodTestCardResult
     */
    export interface PairwiseMoodTestCardResult extends AbstractNSampTestCard._AbstractNSampleTestCardResult {
        pvalues: number[];
        statistics: number[];

        // PolyJson type
        type: 'pairwise_mood';
    }

    export function isPairwiseMoodTestCardResult(toTest: CardResult): toTest is PairwiseMoodTestCardResult { return 'pairwise_mood' === toTest.type; }
}