import { Pipe, PipeTransform, Inject } from '@angular/core';
import { FilterDesc } from 'src/generated-sources';
import { encodeHTML } from 'entities';
import { fairAny } from 'dku-frontend-core';


@Pipe({
    name: 'filterNiceRepr',
    pure: true
})
export class FilterNiceReprPipe implements PipeTransform {
    constructor(@Inject("Expressions") private Expressions: fairAny) { }

    translateConds(input: FilterDesc) {
        if(!input.uiData) {
            return [];
        }
        return input.uiData.conditions!.map((cond) => {
            var op = this.Expressions.getOperatorByName(cond["operator"]);
            if (!op) return "Unknown op: " + encodeHTML(cond["operator"]);
            if (op.repr) return op.repr(cond);
            else return encodeHTML(JSON.stringify(cond));
        });
    }

    anumLiteral(value: any): string {
        return "<span class='alphanum-literal flex'>" + encodeHTML(value) + "</span>";
    }

    transform(input: FilterDesc): string {
        if (!input || !input.enabled || !input.uiData) return "No filter";

        if (input.uiData.mode == "&&") {
            var condStr = this.translateConds(input);
            if (condStr.length == 1) return condStr[0];
            else return condStr.map(function (x) { return "(" + x + ")" }).join(" AND ");
        } else if (input.uiData.mode == "||") {
            var condStr = this.translateConds(input);
            if (condStr.length == 1) return condStr[0];
            else return condStr.map(function (x) { return "(" + x + ")" }).join(" OR ");
        } else if (input.uiData.mode == "CUSTOM") {
            return "<span class='noflex'>Formula: </span>" + this.anumLiteral(input.expression);
        } else if (input.uiData.mode == "SQL") {
            return "<span class='noflex'>SQL: </span>" + this.anumLiteral(input.expression);
        }
        return '';
    }
}
