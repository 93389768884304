import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class PrivateDashboardsAPI {
    constructor(private dkuHttp: DkuHttpService) {
    }

    getInsightWithPayload(projectKey: string, insightId: string): Observable<any> {
        return this.dkuHttp.request<any>('GET', '/dashboards/insights/get-with-payload', { projectKey, insightId });
    }
}
