import {Card} from './../card';
import {PValueAdjustmentMethod} from './../../../compute/stats/pvalue-adjustment-method';
import {PairwiseMoodTestCard} from './../pairwise-mood-test-card';
import {PairwiseTTestCard} from './../pairwise-ttest-card';
import {_AbstractNSampTestCard} from './abstract-nsamp-test-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.common.AbstractPairwiseTestCard
 */
export interface _AbstractPairwiseTestCard extends _AbstractNSampTestCard {
    adjustmentMethod: PValueAdjustmentMethod;
}

export type AbstractPairwiseTestCard = PairwiseTTestCard | PairwiseMoodTestCard;

export function isAbstractPairwiseTestCard(toTest: Card): toTest is AbstractPairwiseTestCard { return ["pairwise_ttest" , "pairwise_mood"].includes(toTest.type); }