import {CardResult, _CardResult} from './card-result';
import {Card} from './card';
import {Distribution} from './../../compute/distributions/distribution';
import {NumericalHistogramFragment} from './fragments/numerical-histogram-fragment';
import {TestDistribution} from './../../compute/computations/univariate/test-distribution';
import {_UnivariateCard} from './univariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.FitDistributionCard
 */
export interface FitDistributionCard extends _UnivariateCard {
    confidenceLevel: number;
    distributions: FitDistributionCard.RequestedDistribution[];

    // PolyJson type
    type: 'fit_distribution';
}

export function isFitDistributionCard(toTest: Card): toTest is FitDistributionCard { return 'fit_distribution' === toTest.type; }

export namespace FitDistributionCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.FitDistributionCard$RequestedDistribution
     */
    export interface RequestedDistribution {
        distribution: Distribution;
        fixedParameters?: Distribution.FittedDistribution | null;
    }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.FitDistributionCard$SingleFitResult
     */
    export interface SingleFitResult {
        distribution: Distribution;
        fit: Distribution.FittedDistribution;
        plot: TestDistribution.PlotData;
        test: TestDistribution.StatTests;
        warnings: string[];
    }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.FitDistributionCard$FitDistributionCardResult
     */
    export interface FitDistributionCardResult extends _CardResult {
        distributions: FitDistributionCard.SingleFitResult[];
        histogram: NumericalHistogramFragment;

        // PolyJson type
        type: 'fit_distribution';
    }

    export function isFitDistributionCardResult(toTest: CardResult): toTest is FitDistributionCardResult { return 'fit_distribution' === toTest.type; }
}