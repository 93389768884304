import { Component, OnDestroy, Inject, Input, ChangeDetectionStrategy, NgZone, EventEmitter, Output } from '@angular/core';
import { APIError, isEnhancedSerializedError } from '@core/dataiku-api/api-error';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { Observable } from 'rxjs';
import { fairAny } from 'dku-frontend-core';
import { ObservableInput } from 'observable-input';

@Component({
    selector: 'api-error-alert',
    templateUrl: './api-error-alert.component.html',
    styleUrls: ['./api-error-alert.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class APIErrorAlertComponent implements OnDestroy {
    @Input() canBeUnexpected: boolean = true;
    @Input() closable: boolean = true;
    @Input() errorFoldable: boolean = false;
    @Input() @ObservableInput() apiError: Observable<APIError | null>;
    @Output() resetError = new EventEmitter<any>();

    open: boolean = true;
    detailedMessageOrMessage: string | null = null; // we use a snapshot to avoid a lot of async subscriptions

    constructor(@Inject('$rootScope') public $root: fairAny,
        private ngZone: NgZone) {
        this.apiError.subscribe(
            error => {
                this.ngZone.run(() =>{
                if (!error) {
                    this.detailedMessageOrMessage = null;
                    return;
                }
                if ('detailedMessage' in error) {
                    this.detailedMessageOrMessage = error.detailedMessage;
                } else {
                    this.detailedMessageOrMessage = error.message;
                }
            });
        });
    }

    ngOnDestroy(): void {
    }

    reset(): void {
        this.resetError.emit();
    }

}
