<div class="modal">
    <modal-header
        [title]="'Rename worksheet'"
        [hasBorder]="false"
    ></modal-header>
    <div class="modal-body dkuform-modal-horizontal">
        <div class="control-group">
            <label for="" class="control-label">New name</label>
            <div class="controls">
                <input [(ngModel)]="data.name" type="text" />
            </div>
        </div>
    </div>
    <div class="modal-footer modal-footer-std-buttons">
        <div class="pull-right">
            <button type="button" class="btn btn--text btn--secondary" (click)="dialogRef.close(false)">Cancel</button>
            <button type="button" class="btn btn--text btn--primary" (click)="dialogRef.close(data.name)" >
                Confirm
            </button>
        </div>
    </div>
</div>
