import {Filter} from './../filtering/filter';
import {Grouping, _Grouping} from './grouping';
import {GroupingResult, _GroupingResult} from './grouping-result';

/**
 * Generated from com.dataiku.dip.eda.compute.grouping.SubsetGrouping
 */
export interface SubsetGrouping extends _Grouping {
    filter: Filter;

    // PolyJson type
    type: 'subset';
}

export function isSubsetGrouping(toTest: Grouping): toTest is SubsetGrouping { return 'subset' === toTest.type; }

export namespace SubsetGrouping {
    /**
     * Generated from com.dataiku.dip.eda.compute.grouping.SubsetGrouping$SubsetGroupingResult
     */
    export interface SubsetGroupingResult extends _GroupingResult {
        filter: Filter;

        // PolyJson type
        type: 'subset';
    }

    export function isSubsetGroupingResult(toTest: GroupingResult): toTest is SubsetGroupingResult { return 'subset' === toTest.type; }
}