import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { UnivariateFrequencyTableCard } from 'src/generated-sources';


@Component({
    selector: 'univariate-frequency-table-card-body',
    templateUrl: './univariate-frequency-table-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/stats-table.less',
        './univariate-frequency-table-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnivariateFrequencyTableCardBodyComponent {
    @Input() params: UnivariateFrequencyTableCard;
    @Input() results: UnivariateFrequencyTableCard.UnivariateFrequencyTableCardResult;

    trackByIndex(index: number) {
        return index;
    }
}
