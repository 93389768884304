import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.bivariate.Covariance
 */
export interface Covariance extends Computation._BivariateComputation {
    // PolyJson type
    type: 'covariance';
}

export function isCovariance(toTest: Computation): toTest is Covariance { return 'covariance' === toTest.type; }

export namespace Covariance {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.bivariate.Covariance$CovarianceResult
     */
    export interface CovarianceResult extends _AvailableResult {
        value: number;

        // PolyJson type
        type: 'covariance';
    }

    export function isCovarianceResult(toTest: ComputationResult): toTest is CovarianceResult { return 'covariance' === toTest.type; }
}