import {CardResult, _CardResult} from './card-result';
import {Card} from './card';
import {Distribution2D} from './../../compute/distributions/distribution2-d';
import {Fit2DDistribution} from './../../compute/computations/bivariate/fit2-ddistribution';
import {_BivariateCard} from './bivariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.Fit2DDistributionCard
 */
export interface Fit2DDistributionCard extends _BivariateCard {
    distribution: Distribution2D;
    xResolution: number;
    yResolution: number;

    // PolyJson type
    type: 'fit_2d_distribution';
}

export function isFit2DDistributionCard(toTest: Card): toTest is Fit2DDistributionCard { return 'fit_2d_distribution' === toTest.type; }

export namespace Fit2DDistributionCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.Fit2DDistributionCard$Fit2DDistributionCardResult
     */
    export interface Fit2DDistributionCardResult extends _CardResult {
        result: Fit2DDistribution.Fit2DDistributionResult;

        // PolyJson type
        type: 'fit_2d_distribution';
    }

    export function isFit2DDistributionCardResult(toTest: CardResult): toTest is Fit2DDistributionCardResult { return 'fit_2d_distribution' === toTest.type; }
}