import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.Sem
 */
export interface Sem extends Computation._UnivariateComputation {
    // PolyJson type
    type: 'sem';
}

export function isSem(toTest: Computation): toTest is Sem { return 'sem' === toTest.type; }

export namespace Sem {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.Sem$SemResult
     */
    export interface SemResult extends _AvailableResult {
        value: number;

        // PolyJson type
        type: 'sem';
    }

    export function isSemResult(toTest: ComputationResult): toTest is SemResult { return 'sem' === toTest.type; }
}