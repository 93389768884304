import {CompactedArray} from './../../utils/compacted-array';
import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {Distribution2D} from './../../distributions/distribution2-d';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.bivariate.Fit2DDistribution
 */
export interface Fit2DDistribution extends Computation._BivariateComputation {
    distribution: Distribution2D;
    xResolution: number;
    yResolution: number;

    // PolyJson type
    type: 'fit_2d_distribution';
}

export function isFit2DDistribution(toTest: Computation): toTest is Fit2DDistribution { return 'fit_2d_distribution' === toTest.type; }

export namespace Fit2DDistribution {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.bivariate.Fit2DDistribution$Fit2DDistributionResult
     */
    export interface Fit2DDistributionResult extends _AvailableResult {
        density: Fit2DDistribution.Density;
        model: Distribution2D.FittedDistribution2D;

        // PolyJson type
        type: 'fit_2d_distribution';
    }

    export function isFit2DDistributionResult(toTest: ComputationResult): toTest is Fit2DDistributionResult { return 'fit_2d_distribution' === toTest.type; }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.bivariate.Fit2DDistribution$Density
     */
    export interface Density {
        data: CompactedArray;
        xMax: number;
        xMin: number;
        yMax: number;
        yMin: number;
    }
}