import { Component, OnInit, TemplateRef, Input, ChangeDetectionStrategy } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { ObservableInput } from 'observable-input';

import { lazyRenderingValve } from '../../utils/rxutils';
import { WindowService } from '../../window.service';

@Component({
    selector: 'lazy-render',
    templateUrl: './lazy-render.component.html',
    styleUrls: ['./lazy-render.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LazyRenderComponent implements OnInit {
    @Input() @ObservableInput() trackData: Observable<any>;
    @Input() @ObservableInput() passThrough: Observable<boolean> = false as any; // Known ObservableInput typing issue
    @Input() visibleTemplate: TemplateRef<any>;
    @Input() invisibleTemplate: TemplateRef<any>;

    visibility$ = new ReplaySubject<boolean>(1);
    displayData$: Observable<any>;

    constructor(private windowService: WindowService) {
    }

    ngOnInit() {
        this.displayData$ = this.passThrough.pipe(
            switchMap(passThrough => passThrough ? this.trackData : lazyRenderingValve(
                this.trackData.pipe(startWith(true)),
                this.visibility$.pipe(debounceTime(300)),
                this.windowService.resize$,
            ))
        );
    }

    onIntersection({ visible }: { visible: boolean }) {
        this.visibility$.next(visible);
    }
}
