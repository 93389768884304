import { realAny } from 'dku-frontend-core';

export function dkuDeepCopy<T extends (object | any[])>(src: T, filter?: Function): T {
    if (Array.isArray(src)) {
        let s = <realAny[]><realAny>src;
        let arr = [];
        for (let i = 0; i < s.length; i++) {
            arr[i] = dkuDeepCopy(s[i], filter);
        }
        return <T><realAny>arr;
    } else if (isObject(src)) {
        let obj: realAny = {};
        dkuForEach(src, (value: realAny, key: realAny) => {
            if (!filter || filter(key)) {
                obj[key] = dkuDeepCopy(value, filter);
            }
        });
        return obj;
    } else {
        let angularJS = (<realAny>window).angular;
        return angularJS.copy(src);
    }
}

export function dkuEquals(one: realAny, two: realAny): boolean {
    let angularJS = (<realAny>window).angular;
    return angularJS.equals(one, two);
}

// Mostly used to migrate previous uses of angular.forEach
export function dkuForEach(input: object | realAny[], iter: (value: realAny, key: any) => void) {
    let angularJS = (<realAny>window).angular;
    return angularJS.forEach(input, iter);
}

export function isObject(value: realAny) {
    return value !== null && typeof value === 'object';
}

export function isFunction(value: realAny) {
    return typeof value === 'function';
}

export function isString(value: realAny) {
    return typeof value === 'string';
}

export function dkuExtend(dst: object, o1: object, ...others: object[]) {
    let angularJS = (<realAny>window).angular;
    return angularJS.extend(dst, o1, ...others);
}

export function mapValues<T>(object: realAny, func: (...args: any[]) => T) {
    return Object.values(object).map(func);
}


export function isDefined(object: realAny): boolean {
    return object !== null && object !== undefined;
}
