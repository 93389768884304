import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { UnivariateSummaryStatsCard, BivariateSummaryStatsCard } from 'src/generated-sources';
import { CardAction } from '@features/eda/worksheet/cards/events';

type SupportedParams = UnivariateSummaryStatsCard | BivariateSummaryStatsCard;
type SupportedResults = UnivariateSummaryStatsCard.UnivariateSummaryStatsCardResult
    | BivariateSummaryStatsCard.BivariateSummaryStatsCardResult;

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'generic-summary-stats-card-body',
    templateUrl: './generic-summary-stats-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/card-spinner.less',
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/test-conclusion.less',
        './generic-summary-stats-card-body.component.less'
    ]
})
export class GenericSummaryStatsCardBodyComponent {
    @Input() results: SupportedResults;
    @Input() params: SupportedParams;
    @Output() action = new EventEmitter<CardAction>();

    trackByIndex(index: number) {
        return index;
    }
}
