<ng-container *ngIf="datasetStatus$ | async as status">
    <div [ngSwitch]="status" *ngIf="datasetFullInfo$ | async as datasetFullInfo" class="dataset-error">
        <div *ngSwitchCase="DatasetStatus.BEING_BUILT">
            <h4 class="dataset-error__header">
                <p>This dataset is being built.</p>
                <a class="btn btn--secondary btn--large"
                (click)="goToJob(datasetFullInfo.currentBuildState.beingBuilt[0].jobId)" ng-if="datasetFullInfo.directAccessOnOriginal">See job</a>
            </h4>
        </div>
        
        <div *ngSwitchCase="DatasetStatus.ABOUT_TO_BE_BUILT">
            <h4 class="dataset-error__header">
                <p>This dataset is about to be built.</p>
                <a class="btn btn--secondary btn--large" ui-sref="goToJob(datasetFullInfo.currentBuildState.aboutToBeBuilt[0].jobId)" ng-if="datasetFullInfo.directAccessOnOriginal">See job</a>
            </h4>
        </div>
        
        <div *ngSwitchCase="DatasetStatus.EMPTY_BUILD">
            <h4 class="dataset-error__header">
                <p>This dataset is empty. You should build it.</p>
                <a class="btn btn--success btn--large" (click)="buildOpenDataset(datasetFullInfo.dataset.projectKey, datasetFullInfo.dataset.name)"> <i class="icon-play"></i>&nbsp;Build</a>
            </h4>
        </div>
        
        <div *ngSwitchCase="DatasetStatus.FAILED_BUILD">
            <h4 class="dataset-error__header">
                <p>Build this dataset</p>
                <a class="btn btn--success btn--large" (click)="buildOpenDataset(datasetFullInfo.dataset.projectKey, datasetFullInfo.dataset.smartName)"> <i class="icon-play"></i>&nbsp;Build</a>
            </h4>
        </div>
    </div>
</ng-container>