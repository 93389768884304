import {Grouping, _Grouping} from './grouping';
import {GroupingResult, _GroupingResult} from './grouping-result';

/**
 * Generated from com.dataiku.dip.eda.compute.grouping.UnionGrouping
 */
export interface UnionGrouping extends _Grouping {
    groupings: Grouping[];

    // PolyJson type
    type: 'union';
}

export function isUnionGrouping(toTest: Grouping): toTest is UnionGrouping { return 'union' === toTest.type; }

export namespace UnionGrouping {
    /**
     * Generated from com.dataiku.dip.eda.compute.grouping.UnionGrouping$UnionGroupingResult
     */
    export interface UnionGroupingResult extends _GroupingResult {
        groupings: GroupingResult[];

        // PolyJson type
        type: 'union';
    }

    export function isUnionGroupingResult(toTest: GroupingResult): toTest is UnionGroupingResult { return 'union' === toTest.type; }
}