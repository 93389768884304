<form [formGroup]="configForm" id="config-form" class="horizontal-flex">
    <div class="card-editor__column">
        <div class="control-group">
            <label class="control-label">
                X Variable
            </label>
            <single-variable-selector [variables]="allVariables$ | async" formControlName="xColumn"></single-variable-selector>
        </div>
        <div class="control-group">
            <label class="control-label">
                Y Variable
            </label>
            <single-variable-selector [variables]="allVariables$ | async" formControlName="yColumn"></single-variable-selector>
        </div>
    </div>
    <div class="card-editor__column">
        <div class="controls" formGroupName="distribution">
            <div class="control-group">
                <label class="control-label control-label--flex">
                    <input type="radio" class="control" formControlName="type" value="kde_2d">
                    2D KDE
                </label>
            </div>
            <div class="control-group">
                <label class="control-label control-label--flex">
                    <input type="radio" class="control" formControlName="type" value="joint_normal">
                    Joint Normal
                </label>
            </div>
            <div class="control-group" *ngIf="distributionGroup.controls.x_relative_bandwidth.enabled">
                <label class="control-label">
                    X relative bandwidth (%)
                </label>
                <div class="controls">
                    <input type="number" formControlName="x_relative_bandwidth"  matTooltip="Factor for scaling the horizontal KDE bandwidth, as a percentage of the standard deviation of X">
                </div>
                <label class="control-label">
                    Y relative bandwidth (%)
                </label>
                <div class="controls">
                    <input type="number" formControlName="y_relative_bandwidth"  matTooltip="Factor for scaling the vertical KDE bandwidth, as a percentage of the standard deviation of Y">
                </div>
            </div>

        </div>
    </div>
</form>