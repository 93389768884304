import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { QuantilesTableCard } from 'src/generated-sources';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'quantiles-table-card-body',
    templateUrl: './quantiles-table-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/stats-table.less',
        './quantiles-table-card-body.component.less'
    ]
})
export class QuantilesTableCardBodyComponent {
    @Input() params: QuantilesTableCard;
    @Input() results: QuantilesTableCard.QuantilesTableCardResult;

    trackByIndex(index: number) {
        return index;
    }
}
