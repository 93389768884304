import { SchemaColumn, Variable } from 'src/generated-sources';

export function columnsToVariables(schemaColumns: SchemaColumn[]): Variable[] {
    return schemaColumns.map(column => {
        switch (column.type) {
            case 'float':
            case 'double':
            case 'smallint':
            case 'int':
            case 'bigint':
            case 'tinyint':
                return { type: Variable.Type.CONTINUOUS, name: column.name };
            default:
                return { type: Variable.Type.CATEGORICAL, name: column.name };
        }
    });
}