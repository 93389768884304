import { NgModule } from '@angular/core';
import { EditableListModule } from '../editable-list/editable-list.module';
import { ValuesListComponent } from './values-list.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DowngradedValuesListComponent } from './downgraded/downgraded-values-list.component';


@NgModule({
    declarations: [ 
        ValuesListComponent, 
        DowngradedValuesListComponent 
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        EditableListModule
    ],
    exports: [ ValuesListComponent ],
    entryComponents: [ DowngradedValuesListComponent ]
})
export class ValuesListModule { }
