import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {Grouping} from './../../grouping/grouping';
import {PValueAdjustmentMethod} from './../../stats/pvalue-adjustment-method';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.PairwiseTTest
 */
export interface PairwiseTTest extends Computation._UnivariateComputation {
    adjustmentMethod: PValueAdjustmentMethod;
    grouping: Grouping;

    // PolyJson type
    type: 'pairwise_ttest';
}

export function isPairwiseTTest(toTest: Computation): toTest is PairwiseTTest { return 'pairwise_ttest' === toTest.type; }

export namespace PairwiseTTest {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.PairwiseTTest$PairwiseTTestResult
     */
    export interface PairwiseTTestResult extends _AvailableResult {
        pvalues: number[];
        statistics: number[];

        // PolyJson type
        type: 'pairwise_ttest';
    }

    export function isPairwiseTTestResult(toTest: ComputationResult): toTest is PairwiseTTestResult { return 'pairwise_ttest' === toTest.type; }
}