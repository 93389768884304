import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
    selector: 'dataset-selector',
    template: '<angularjs-dataset-selector [dataset]="dataset" [datasetChange]="datasetChange" [availableDatasets]="availableDatasets" [required]="required"></angularjs-dataset-selector>',
})
export class DatasetSelectorComponent {
    @Input() dataset: string;
    @Input() availableDatasets: any[];
    @Input() required?: boolean;
    @Output() datasetChange: EventEmitter<String> = new EventEmitter<String>();
}
