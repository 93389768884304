import { Component, ChangeDetectionStrategy, Inject, Input } from '@angular/core';
import { fairAny } from 'dku-frontend-core';

@Component({
  selector: 'doclink-wrapper',
  templateUrl: './doclink-wrapper.component.html',
  styleUrls: ['./doclink-wrapper.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoclinkWrapperComponent {
    @Input() page: string;

    get url() {
        return this.$rootScope.versionDocRoot + this.page + '.html';
    }

    constructor(
        @Inject('$rootScope') private $rootScope: fairAny,
    ) { }
}
