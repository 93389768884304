<ng-container *ngIf="results.reason === 'NOT_COMPUTED'">
    <span class="dku-loader icon-spin card-spinner" [class.m8]="params.type === 'univariate_header' || params.type === 'bivariate_header'"></span>
</ng-container>

<ng-container *ngIf="results.reason === 'FAILURE'">
    <div class="alert alert-danger">
        <i class="icon-warning-sign"></i>
        {{ results.message }}
    </div>
</ng-container>
