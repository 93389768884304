import {Filter, _Filter} from './filter';

/**
 * Generated from com.dataiku.dip.eda.compute.filtering.AllFilter
 */
export interface AllFilter extends _Filter {
    // PolyJson type
    type: 'all';
}

export function isAllFilter(toTest: Filter): toTest is AllFilter { return 'all' === toTest.type; }