import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { Card } from 'src/generated-sources';
import { CardAction } from '../events';

@Component({
    selector: 'card-help',
    templateUrl: './card-help.component.html',
    styleUrls: ['./card-help.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardHelpComponent {
    @Input() params: Card;
    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;
    @Output() action = new EventEmitter<CardAction>();

    static isAvailableForCard(card: Card): boolean {
        switch (card.type) {
            case 'ttest_ztest_1samp':
                return !card.knownStdDev; // TODO: write content for z-test
            case 'shapiro':
            case 'ks_test_2samp':
            case 'sign_test_1samp':
            case 'chi2_independence_test':
            case 'mood_nsamp':
            case 'mood_test_2samp':
            case 'pairwise_mood':
            case 'oneway_anova':
            case 'pairwise_ttest':
            case 'ttest_2samp':
                return true;
        }
        return false;
    }
}
