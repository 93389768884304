import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ShapiroNormalityTestCard } from 'src/generated-sources';

@Component({
    selector: 'shapiro-normality-test-card-help',
    templateUrl: './shapiro-normality-test-card-help.component.html',
    styleUrls: ['./shapiro-normality-test-card-help.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShapiroNormalityTestCardHelpComponent {
    @Input() params: ShapiroNormalityTestCard;
    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;
}
