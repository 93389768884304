<div>
    <modal-header [title]="data.title" [icon]="data.icon"></modal-header>
    <div class="modal-body flex">
        {{data.message}}
    </div>
    <div class="modal-footer modal-footer-std-buttons">
        <div class="pull-right" *ngIf="!data.danger">
            <button type="button" class="btn btn-default" (click)="cancel();">Cancel</button>
            <button type="button" class="btn btn-primary" (click)="confirm();" autofocus>Confirm</button>
        </div>
        <div class="pull-right" *ngIf="data.danger">
            <!-- Dangerous modals don't validate on enter-->
            <button type="button" class="btn btn-default" (click)="cancel();" tabindex="-1">Cancel</button>
            <button type="button" class="btn btn-danger" (click)="confirm();" tabindex="-1">Confirm</button>
        </div>
    </div>
</div>