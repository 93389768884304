<div class="card-layout">
    <div class="card-layout__section card-layout__section--vertical">
        <div class="card-layout__section">
            <div class="card-layout__title">Tested hypothesis</div>
            <div class="card-layout__content">
                <table class="stats-table">
                    <tbody class="stats-table__body">
                        <tr class="stats-table__row stats-table__row--colored">
                            <th class="stats-table__head">Significance level</th>
                            <td class="stats-table__cell">
                                {{ 1 - params.confidenceLevel | smarterNumber }}
                            </td>
                            <th class="stats-table__head">𝘱-values correction method</th>
                            <td class="stats-table__cell">
                                <ng-container [ngSwitch]="params.adjustmentMethod">
                                    <ng-container *ngSwitchCase="'NONE'">None</ng-container>
                                    <ng-container *ngSwitchCase="'BONFERRONI'">Bonferroni</ng-container>
                                    <ng-container *ngSwitchCase="'HOLM_BONFERRONI'">Holm-Bonferroni</ng-container>
                                    <ng-container *ngSwitchDefault>[NOT IMPLEMENTED]</ng-container>
                                </ng-container>
                            </td>
                        </tr>
                        <tr class="stats-table__row stats-table__row--colored">
                            <th class="stats-table__head">Hypothesis tested</th>
                            <td class="stats-table__cell">
                                <u>{{ params.xColumn.name }}</u>
                                {{ locationParameterName }}
                                is identical in each pair of populations
                            </td>
                            <th class="stats-table__head"></th>
                            <td class="stats-table__cell"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-layout__section">
            <div class="card-layout__title">P-values table</div>
            <div class="card-layout__content">
                <table class="stats-table">
                    <tbody class="stats-table__body">
                        <tr class="stats-table__row">
                            <th class="stats-table__head stats-table__head--colored"></th>
                            <th class="stats-table__head">Count</th>
                            <th class="stats-table__head"></th>
                            <th
                                class="stats-table__head"
                                *ngFor="let colGroup of results.groups; let colIdx = index;"
                            >
                                {{ colGroup.filter | filterName }}
                            </th>
                        </tr>
                        <tr
                            class="stats-table__row"
                            *ngFor="let rowGroup of results.groups; let rowIdx = index;"
                        >
                            <th class="stats-table__head stats-table__head--colored">{{ rowGroup.filter | filterName }}</th>
                            <td class="stats-table__cell stats-table__cell--left">{{ rowGroup.boxPlot.countComplete }}</td>
                            <td class="boxplot-cell stats-table__cell">
                                <lazy-render
                                    [visibleTemplate]="visibleBoxPlot"
                                    [trackData]="boxPlotsOptions"
                                >
                                    <ng-template #visibleBoxPlot>
                                        <div
                                            echarts
                                            *ngIf="boxPlotsOptions && boxPlotsOptions[rowIdx]"
                                            [options]="boxPlotsOptions[rowIdx]"
                                        >
                                        </div>
                                    </ng-template>
                                </lazy-render>
                            </td>

                            <ng-container *ngFor="let colGroup of results.groups; let colIdx = index">
                                <td
                                    *ngIf="rowIdx > colIdx; else blankCell"
                                    class="stats-table__cell stats-table__cell--left"
                                >
                                    <colored-pvalue
                                        [pvalue]="results.pvalues[getFlattenedIndex(colIdx, rowIdx)]"
                                        [significanceLevel]="1 - params.confidenceLevel"
                                    ></colored-pvalue>
                                </td>
                                <ng-template #blankCell>
                                    <td class="stats-table__cell stats-table__cell--left">-</td>
                                </ng-template>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
