import { Injectable } from '@angular/core';
import d3 from 'd3';

/**
 * A global registry of colors assigned to certain variables.
 *
 * Colors are cycled from 'categoryColorPalette', even indexes first, odd colors next, for higher contrast.
 *
 * Components using an independent palette should use getColorFromIndex, providing their own offsets starting from 0.
 * Components using a palette shared with other components should use getColorForVariable,
 *  providing the name of the color to share as parameter
 */
@Injectable({
    providedIn: 'root'
})
export class ColorsService {
    private categoryColorPalette: string[];
    private categoryColorMap: Map<string, string> = new Map<string, string>();
    private nextCategoryColorIndex = 0;

    constructor() {
        this.categoryColorPalette = d3.scale.category20().range().concat(d3.scale.category20b().range());
    }

    private getNextNamedColor(): string {
        const ret = this.categoryColorPalette[this.nextCategoryColorIndex];
        this.nextCategoryColorIndex += 2;
        if (this.nextCategoryColorIndex >= this.categoryColorPalette.length) {
            // toggling even/odd color indexes
            this.nextCategoryColorIndex = (this.nextCategoryColorIndex - this.categoryColorPalette.length + 1) % 2;
        }
        return ret;
    }

    public getColorForVariable(name: string): string {
        let ret = this.categoryColorMap.get(name);
        if (!ret) {
            ret = this.getNextNamedColor();
            this.categoryColorMap.set(name, ret);
        }
        return ret;
    }

    public getColorFromIndex(index: number): string {
        const offset = Math.floor(index / this.categoryColorPalette.length) % 2;
        return this.categoryColorPalette[(index * 2 + offset) % this.categoryColorPalette.length];
    }
}
