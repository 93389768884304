import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CredentialsListModule } from '../credentials-list/credentials-list.module';
import { ConnectionPropertiesListComponent } from './connection-properties-list.component';
import { DowngradedConnectionPropertiesListComponent } from './downgraded/downgraded-connection-properties-list.component';


@NgModule({
    declarations: [ ConnectionPropertiesListComponent, DowngradedConnectionPropertiesListComponent ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CredentialsListModule
    ],
    exports: [ ConnectionPropertiesListComponent ],
    entryComponents: [ DowngradedConnectionPropertiesListComponent ]
})
export class ConnectionPropertiesListModule { }
