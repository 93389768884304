import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.Variance
 */
export interface Variance extends Computation._UnivariateComputation {
    // PolyJson type
    type: 'variance';
}

export function isVariance(toTest: Computation): toTest is Variance { return 'variance' === toTest.type; }

export namespace Variance {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.Variance$VarianceResult
     */
    export interface VarianceResult extends _AvailableResult {
        value: number;

        // PolyJson type
        type: 'variance';
    }

    export function isVarianceResult(toTest: ComputationResult): toTest is VarianceResult { return 'variance' === toTest.type; }
}