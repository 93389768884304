import {DatasetOrdering} from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/input/dataset-ordering';
import {DatasetSelectionToMemTable} from './dataset-selection-to-mem-table';
import {FilterDesc} from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
import {SamplingParam} from './sampling-param';
import {StreamableDatasetSelection} from './streamable-dataset-selection';

/**
 * Generated from com.dataiku.dip.datasets.DatasetSelection
 */
export interface _DatasetSelection extends SamplingParam {
    filter: FilterDesc;
    latestPartitionsN: number;
    ordering: DatasetOrdering;
    partitionSelectionMethod: DatasetSelection.PartitionSelectionMethod;
    selectedPartitions?: string[] | null;
}

export type DatasetSelection = DatasetSelectionToMemTable | StreamableDatasetSelection;

export namespace DatasetSelection {
    /**
     * Generated from com.dataiku.dip.datasets.DatasetSelection$PartitionSelectionMethod
     */
    export enum PartitionSelectionMethod {
        ALL = 'ALL',
        SELECTED = 'SELECTED',
        LATEST_N = 'LATEST_N'
    }
}