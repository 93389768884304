<ng-container [formGroup]="configForm">
    <ng-container *ngIf="isCategorical; else NUMERICAL">
        <div class="control-group">
            <label class="control-label">Max nb. of bins</label>
            <div class="controls">
                <input
                    type="number"
                    min="1"
                    formControlName="maxValues"
                >
            </div>
        </div>
        <div
            class="control-group"
        >
            <label>
                <input
                    type="checkbox"
                    class="form__checkbox"
                    formControlName="groupWithOthers"
                >
                Group extra values in an "Others" category
            </label>
        </div>
    </ng-container>
    <ng-template #NUMERICAL>
        <div class="control-group">
            <label class="control-label">Binning mode</label>
            <div class="controls binning-selector">
                <dku-bs-select-form-control
                    formControlName="binningMode"
                    ngOptions="v.key as v.label for v in list"
                    [list]="supportedBinningModes"
                    [layout]="'list'"
                >
                </dku-bs-select-form-control>
            </div>
            <label class="control-label">
                <ng-container [ngSwitch]="configForm.value.binningMode">
                    <ng-container *ngSwitchCase="'AUTO'">Max nb. of bins</ng-container>
                    <ng-container *ngSwitchCase="'FIXED_NB'">Nb. of bins</ng-container>
                    <ng-container *ngSwitchCase="'CUSTOM'">Custom bin boundaries</ng-container>
                </ng-container>
            </label>
                    <div class="controls" *ngIf="(configForm.value.binningMode == BinningMode.AUTO) || (configForm.value.binningMode == BinningMode.FIXED_NB)">
                <input
                    type="number"
                    min="1"
                    formControlName="maxValues"
                >
            </div>
            <div class="controls" *ngIf="configForm.value.binningMode == BinningMode.CUSTOM">
                <simple-number-list formControlName="customBinningBoundaries"></simple-number-list>
            </div>
        </div>
    </ng-template>
</ng-container>
