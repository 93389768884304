import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {Grouping} from './../../grouping/grouping';
import {PValueAdjustmentMethod} from './../../stats/pvalue-adjustment-method';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.PairwiseMoodTest
 */
export interface PairwiseMoodTest extends Computation._UnivariateComputation {
    adjustmentMethod: PValueAdjustmentMethod;
    grouping: Grouping;

    // PolyJson type
    type: 'pairwise_mood_test';
}

export function isPairwiseMoodTest(toTest: Computation): toTest is PairwiseMoodTest { return 'pairwise_mood_test' === toTest.type; }

export namespace PairwiseMoodTest {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.PairwiseMoodTest$PairwiseMoodTestResult
     */
    export interface PairwiseMoodTestResult extends _AvailableResult {
        pvalues: number[];
        statistics: number[];

        // PolyJson type
        type: 'pairwise_mood_test';
    }

    export function isPairwiseMoodTestResult(toTest: ComputationResult): toTest is PairwiseMoodTestResult { return 'pairwise_mood_test' === toTest.type; }
}