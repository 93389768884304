import {Abstract2SampTestCard, _Abstract2SampTestCard} from './common/abstract2-samp-test-card';
import {CardResult} from './card-result';
import {Card} from './card';
import {EmpiricalCDF} from './../../compute/computations/univariate/empirical-cdf';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.KsTest2SampCard
 */
export interface KsTest2SampCard extends _Abstract2SampTestCard {
    // PolyJson type
    type: 'ks_test_2samp';
}

export function isKsTest2SampCard(toTest: Card): toTest is KsTest2SampCard { return 'ks_test_2samp' === toTest.type; }

export namespace KsTest2SampCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.KsTest2SampCard$KsTest2SampCardResult
     */
    export interface KsTest2SampCardResult extends Abstract2SampTestCard._Abstract2SampTestCardResult {
        cdf1: EmpiricalCDF.EmpiricalCDFResult;
        cdf2: EmpiricalCDF.EmpiricalCDFResult;
        pvalue: number;
        statistic: number;

        // PolyJson type
        type: 'ks_test_2samp';
    }

    export function isKsTest2SampCardResult(toTest: CardResult): toTest is KsTest2SampCardResult { return 'ks_test_2samp' === toTest.type; }
}