import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditableListModule } from '../editable-list/editable-list.module';
import { CredentialsListComponent } from './credentials-list.component';
import { DowngradedCredentialsListComponent } from './downgraded/downgraded-credentials-list.component';


@NgModule({
    declarations: [ 
        CredentialsListComponent, 
        DowngradedCredentialsListComponent 
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        EditableListModule
    ],
    exports: [ CredentialsListComponent ],
    entryComponents: [ DowngradedCredentialsListComponent ]
})
export class CredentialsListModule { }
