import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { WorksheetRootCard, Card } from 'src/generated-sources';
import { CardActionType, CardAction } from '@features/eda/worksheet/cards/events';
import { Observable } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { produce } from 'immer';
import { WorksheetContextService } from '@features/eda/worksheet-state/worksheet.context.service';
import { resetCardIds } from '@features/eda/card-utils';
import { WT1Service } from '@core/dataiku-wt1/wt1.service';
import { CdkDragDrop, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import { CollapsingService, CollapsibleTopLevelCard } from '@features/eda/collapsing.service';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'worksheet-root-card',
    templateUrl: './worksheet-root-card.component.html',
    styleUrls: ['./worksheet-root-card.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorksheetRootCardComponent implements OnDestroy {
    @Input() params: WorksheetRootCard;
    @Input() results: WorksheetRootCard.WorksheetRootCardResult;
    @Input() readOnly = false;
    @Output() action = new EventEmitter<CardAction>();
    @ViewChildren(CdkDrag, {}) cdkDragRefs: QueryList<CdkDrag>;

    constructor(
        private worksheetContextService: WorksheetContextService,
        private wt1Service: WT1Service,
        private collapsingService: CollapsingService
    ) { }

    discoverRemoteDragHandle(index: number, element: HTMLElement | undefined) {
        const drag = this.cdkDragRefs.toArray()[index];
        const dragRef = drag && drag._dragRef;
        dragRef.withHandles(element ? [element] : []);
    }

    trackByCardId(index: number, card: Card) {
        return card.id;
    }

    handleTopLevelCardAction(index: number, action: CardAction) {
        if (action.type === CardActionType.UPDATE) {
            this.action.emit({
                type: CardActionType.UPDATE,
                newParams: produce(this.params, draft => {
                    draft.cards[index] = action.newParams;
                })
            });

        } else if (action.type === CardActionType.DELETE) {
            this.action.emit({
                type: CardActionType.UPDATE,
                newParams: produce(this.params, draft => {
                    draft.cards.splice(index, 1);
                })
            });

        } else if (action.type === CardActionType.ADD) {
            this.action.emit({
                type: CardActionType.ADD,
                card: resetCardIds(action.card)
            });
        } else if (action.type === CardActionType.PUBLISH) {
            this.action.emit(action);
        } else if (action.type === CardActionType.DEBUG) {
            this.worksheetContextService.debugCard(action.card);
        } else if (action.type === CardActionType.HIGHLIGHT) {
            this.wt1Service.event('statistics-worksheet-highlight-toggle', {
                highlightEnabled: !!action.filter,
                highlightType: action.filter && action.filter.type
            });

            this.action.emit({
                type: CardActionType.UPDATE,
                newParams: { ...this.params, highlightFilter: action.filter }
            });
        } else if (action.type === CardActionType.REORDER_HEADER_CARD) {
            this.action.emit(action);
        }
    }
    ngOnDestroy() { }

    drop(event: CdkDragDrop<string[]>) {
        const newCard = produce(this.params, draft => {
            moveItemInArray(draft.cards, event.previousIndex, event.currentIndex);
        });
        this.action.emit({
            type: CardActionType.UPDATE,
            newParams: newCard,
            immediate: true
        });
        this.wt1Service.event('statistics-drag-drop-reorder-cards', {});
    }

    isCardCollapsible(id: string): Observable<any> {
        return this.collapsingService.watchIsCollapsed(new CollapsibleTopLevelCard(id))
        .pipe(
            untilDestroyed(this),
            map(v => ({ v }))
        );
    }
}
