import {AbstractNSampTestCard} from './common/abstract-nsamp-test-card';
import {CardResult} from './card-result';
import {Card} from './card';
import {_AbstractPairwiseTestCard} from './common/abstract-pairwise-test-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.PairwiseTTestCard
 */
export interface PairwiseTTestCard extends _AbstractPairwiseTestCard {
    // PolyJson type
    type: 'pairwise_ttest';
}

export function isPairwiseTTestCard(toTest: Card): toTest is PairwiseTTestCard { return 'pairwise_ttest' === toTest.type; }

export namespace PairwiseTTestCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.PairwiseTTestCard$PairwiseTTestCardResult
     */
    export interface PairwiseTTestCardResult extends AbstractNSampTestCard._AbstractNSampleTestCardResult {
        pvalues: number[];
        statistics: number[];

        // PolyJson type
        type: 'pairwise_ttest';
    }

    export function isPairwiseTTestCardResult(toTest: CardResult): toTest is PairwiseTTestCardResult { return 'pairwise_ttest' === toTest.type; }
}