import {Beta} from './beta';
import {Binomial} from './binomial';
import {Exponential} from './exponential';
import {Laplace} from './laplace';
import {LogNormal} from './log-normal';
import {NormalMixture} from './normal-mixture';
import {Normal} from './normal';
import {Pareto} from './pareto';
import {Poisson} from './poisson';
import {Triangular} from './triangular';
import {Weibull} from './weibull';

/**
 * Generated from com.dataiku.dip.eda.compute.distributions.Distribution
 */
export interface _Distribution {}

export type Distribution = Beta | NormalMixture | Poisson | Normal | Weibull | Binomial | LogNormal | Laplace | Triangular | Pareto | Exponential;

export namespace Distribution {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.Distribution$FittedDistribution
     */
    export interface _FittedDistribution {}

    export type FittedDistribution = Pareto.FittedPareto | Beta.FittedBeta | Exponential.FittedExponential | Binomial.FittedBinomial | Weibull.FittedWeibull | LogNormal.FittedLogNormal | Laplace.FittedLaplace | Poisson.FittedPoisson | NormalMixture.FittedNormalMixture | Triangular.FittedTriangular | Normal.FittedNormal;

    export function isFittedDistribution(toTest: Distribution.FittedDistribution): toTest is FittedDistribution { return ["pareto" , "beta" , "exponential" , "binomial" , "weibull" , "lognormal" , "laplace" , "poisson" , "normal_mixture" , "triangular" , "normal"].includes(toTest.type); }
}