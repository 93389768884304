import {Computation, _Computation} from './../computation';
import {ComputationResult} from './../computation-result';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.common.DummyComputation
 */
export interface DummyComputation extends _Computation {
    // PolyJson type
    type: 'dummy';
}

export function isDummyComputation(toTest: Computation): toTest is DummyComputation { return 'dummy' === toTest.type; }

export namespace DummyComputation {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.common.DummyComputation$DummyComputationResult
     */
    export interface DummyComputationResult extends _AvailableResult {
        // PolyJson type
        type: 'dummy';
    }

    export function isDummyComputationResult(toTest: ComputationResult): toTest is DummyComputationResult { return 'dummy' === toTest.type; }
}