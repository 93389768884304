import {Distribution, _Distribution} from './distribution';

/**
 * Generated from com.dataiku.dip.eda.compute.distributions.Weibull
 */
export interface Weibull extends _Distribution {
    // PolyJson type
    type: 'weibull';
}

export function isWeibull(toTest: Distribution): toTest is Weibull { return 'weibull' === toTest.type; }

export namespace Weibull {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.Weibull$FittedWeibull
     */
    export interface FittedWeibull extends Distribution._FittedDistribution {
        scale: number;
        shape: number;

        // PolyJson type
        type: 'weibull';
    }

    export function isFittedWeibull(toTest: Distribution.FittedDistribution): toTest is FittedWeibull { return 'weibull' === toTest.type; }
}