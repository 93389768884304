<values-list [ngModel]="items" 
    (ngModelChange)="handleChange($event)" 
    (onValidityChange)="onValidityChange.emit($event)"
    [valuePlaceholder]="valuePlaceholder"
    [valueIdentifier]="valueIdentifier"
    [required]="required"
    [addLabel]="addLabel" 
    [sortable]="sortable" 
    [hasDivider]="hasDivider" 
    [focusOnEnter]="focusOnEnter"
    [fullWidthList]="fullWidthList"
    [disableAdd]="disableAdd"
    [suggestions]="suggestions" 
    (onAdd)="onAdd.emit($event)"
    (onDelete)="onDelete.emit($event)"
    (onFocus)="onFocus.emit($event)" 
    (onBlur)="onBlur.emit($event)"
    (onReorder)="onReorder.emit($event)">
</values-list>
