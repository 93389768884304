import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Card, CardResult } from 'src/generated-sources';

@Component({
    selector: 'unavailable-card-body',
    templateUrl: './unavailable-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/card-spinner.less',
        './unavailable-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnavailableCardBodyComponent {
    @Input() params: Card;
    @Input() results: CardResult.UnavailableCardResult;
}
