import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Inject } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { map, switchMap, filter, distinctUntilChanged } from 'rxjs/operators';
import { UsabilityComputer, ProjectsCRUDController } from 'src/generated-sources';
import { IScope } from 'angular';
import { fairAny } from 'dku-frontend-core';

@UntilDestroy()
@Component({
    selector: 'duplicate-worksheet-dialog',
    templateUrl: './duplicate-worksheet-dialog.component.html',
    styleUrls: ['./duplicate-worksheet-dialog.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DuplicateWorksheetDialogComponent implements OnInit, OnDestroy {
    projects: ProjectsCRUDController.ProjectHead[] = [];
    datasets: UsabilityComputer.UsableComputable[] = [];
    projectSelectParams: { noneSelectedText: string };
    form = this.fb.group({
        project: this.fb.control(null, [Validators.required]),
        dataset: this.fb.control(null, [Validators.required])
    });

    constructor(
        private currentRoute: CurrentRouteService,
        private DataikuAPI: DataikuAPIService,
        private changeDetectionRef: ChangeDetectorRef,
        public dialogRef: MatDialogRef<DuplicateWorksheetDialogComponent>,
        @Inject('$state') private $state: fairAny,
        @Inject('$rootScope') private $rootScope: IScope,
        private fb: FormBuilder
    ) {
        this.projectSelectParams = {
            noneSelectedText: 'No project selected'
        };
    }

    ngOnInit() {
        const originalProjectKey = this.currentRoute.projectKey;
        const originalDatasetName = this.currentRoute.datasetName;

        this.DataikuAPI.projects.listHeads('WRITE_CONF')
            .pipe(
                untilDestroyed(this)
            )
            .subscribe((writableProjects) => {
                if (writableProjects && writableProjects.length) {
                    const currentProjectWritable = writableProjects.some(_ => _.projectKey === originalProjectKey);
                    const selectedProject = currentProjectWritable ? originalProjectKey : writableProjects[0].projectKey;

                    this.projects = writableProjects;

                    this.form.patchValue({
                        project: selectedProject
                    });

                    this.changeDetectionRef.detectChanges();
                }
            });

        this.form.controls.project.valueChanges.pipe(
            distinctUntilChanged(),
            filter(projectKey => projectKey), // if not empty
            switchMap(projectKey => this.DataikuAPI.flow.listUsableComputables(projectKey, { datasetsOnly: true })
                .pipe(
                    map(datasets => [projectKey, datasets])
                )
            )
        ).subscribe(([projectKey, datasets]) => {
            const selectedDataset = projectKey === originalProjectKey ? originalDatasetName : null;

            this.datasets = datasets;

            this.form.patchValue({
                dataset: selectedDataset
            });

            this.changeDetectionRef.detectChanges();
            this.$rootScope.$applyAsync();
        });
    }

    importDataset(projectKey: string) {
        this.$state.go('projects.project.datasets.new', {
            projectKey
        });
    }

    confirm() {
        this.dialogRef.close({
            projectKey: this.form.controls.project.value,
            datasetName: this.form.controls.dataset.value,
        });
    }

    ngOnDestroy() {

    }

}
