import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {Grouping} from './../../grouping/grouping';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.OneWayAnova
 */
export interface OneWayAnova extends Computation._UnivariateComputation {
    grouping: Grouping;

    // PolyJson type
    type: 'one_way_anova';
}

export function isOneWayAnova(toTest: Computation): toTest is OneWayAnova { return 'one_way_anova' === toTest.type; }

export namespace OneWayAnova {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.OneWayAnova$OneWayAnovaResult
     */
    export interface OneWayAnovaResult extends _AvailableResult {
        pvalue: number;
        statistic: number;

        // PolyJson type
        type: 'one_way_anova';
    }

    export function isOneWayAnovaResult(toTest: ComputationResult): toTest is OneWayAnovaResult { return 'one_way_anova' === toTest.type; }
}