import { Component, Input, forwardRef, OnDestroy } from '@angular/core';
import { EditableListBase } from '../editable-list/editable-list.component';
import { NG_VALUE_ACCESSOR, FormBuilder, Validators } from '@angular/forms';
import { FormArrayRepeat } from '@utils/form-array-repeat';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { TolerantFormGroup } from '@utils/tolerant-form-group';

@UntilDestroy()
@Component({
    selector: 'key-values-list',
    templateUrl: './key-values-list.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => KeyValuesListComponent),
            multi: true
        }
    ]
})

/**
 * Editable list of key / value pairs.
 * Uses editable-list-component with a dedicated template.
 * 
 * @example
 * <key-values-list-component items="items"></key-values-list-component>
 */
export class KeyValuesListComponent extends EditableListBase implements OnDestroy {
    @Input() addLabel: string = 'Add Key/Value';
    @Input() keyPlaceholder: string = 'A key';
    @Input() valuePlaceholder: string = 'A value';
    @Input() keyIdentifier: string = 'key';
    @Input() valueIdentifier: string = 'value';
    @Input() keyRequired: boolean = false;
    @Input() valueRequired: boolean = false;
    @Input() required: boolean = true;
    formBuilder: FormBuilder;
    private changesSubscription: Subscription;

    constructor(fb: FormBuilder) {
        super();
        this.formBuilder = fb;
    }

    ngOnChanges() {
        let keyValidators: any[] = [];
        let valueValidators: any[] = [];

        if (this.required) {
            this.keyRequired = true;
            this.valueRequired = true;
        }

        if (this.keyRequired) {
            keyValidators.push(Validators.required);
        }

        if (this.valueRequired) {
            valueValidators.push(Validators.required);
        }
        
        this.itemsFormArray = new FormArrayRepeat(() => {
            return new TolerantFormGroup({
                [this.keyIdentifier]: this.formBuilder.control('', keyValidators),
                [this.valueIdentifier]: this.formBuilder.control('', valueValidators)
            });
        });

        if (this.items && this.items.length) {
            this.itemsFormArray.setValue(this.items);
        }

        if (this.changesSubscription) {
            this.changesSubscription.unsubscribe();
        }

        this.changesSubscription = this.itemsFormArray.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((items) => { this.onChange(items); });
    }

    ngOnDestroy(): void { }
}
