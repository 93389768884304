import { Directive, Input, ElementRef, OnChanges, OnDestroy, ComponentRef, OnInit } from '@angular/core';
import { QaSelectors, getAttributeName, QaSelectorService } from './qa-selector.service';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { QaSelectorComponent } from './qa-selector.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { fromLongPressEvent } from 'dku-frontend-core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { fromEvent, EMPTY, merge, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * Two input formats are accepted:
 * - { elementName: { property1Name: property1Value, ... }, ...}
 * - "elementName" (equivalent to: {elementName:{}})
 */
function expandInput(input: QaSelectors | string): QaSelectors {
    if (typeof input === 'string') {
        return { [input]: {} };
    }
    return input;
}

@UntilDestroy()
@Directive({
    selector: '[qa]'
})
export class QaSelectorDirective implements OnChanges, OnDestroy, OnInit {
    // User provided input (accept multiple formats, see expandInput())
    @Input() qa: QaSelectors | string;

    // Expanded input, canonical format
    qaSelectors: QaSelectors = {};

    overlayRef?: OverlayRef;
    componentRef?: ComponentRef<QaSelectorComponent>;

    constructor(
        private el: ElementRef<HTMLElement>,
        private overlay: Overlay,
        private qaSelectorService: QaSelectorService) { }

    ngOnInit() {
        this.qaSelectorService.isOverlayEnabled.pipe(
            switchMap(flag => {
                if (!flag) { return of(null); }
                return merge(
                    fromLongPressEvent(this.el.nativeElement, 500, () => !this.isOverlayOpened),
                    fromEvent(this.el.nativeElement, 'mouseleave')
                );
            }),
            untilDestroyed(this)
        ).subscribe(() => this.closeOverlay());

        this.qaSelectorService.isOverlayEnabled.pipe(
            switchMap((flag) => flag ? fromEvent(this.el.nativeElement, 'mouseenter') : EMPTY),
            untilDestroyed(this),
        ).subscribe(() => this.openOverlay());
    }

    ngOnDestroy() {
        this.closeOverlay();
    }

    closeOverlay() {
        if (this.isOverlayOpened) {
            this.overlayRef!.dispose();
            delete this.overlayRef;
            delete this.componentRef;
        }
    }

    get isOverlayOpened() {
        return !!this.overlayRef;
    }

    openOverlay() {
        if (this.isOverlayOpened) {
            // Already opened
            return;
        }
        const positionStrategy = this.overlay.position()
            .flexibleConnectedTo(this.el.nativeElement)
            .withPositions([{
                originX: 'start',
                originY: 'top',
                overlayX: 'start',
                overlayY: 'top'
            }]);

        const config = new OverlayConfig();
        config.positionStrategy = positionStrategy;
        config.hasBackdrop = false;
        config.scrollStrategy = this.overlay.scrollStrategies.reposition();
        config.panelClass = 'overlay-no-pointer-events';
        this.overlayRef = this.overlay.create(config);
        const portal = new ComponentPortal(QaSelectorComponent);
        this.componentRef = this.overlayRef!.attach(portal) as ComponentRef<QaSelectorComponent>;
        this.componentRef.instance.setData(this.qaSelectors);
        this.componentRef.instance.setElementAndOverlayRef(this.el.nativeElement, this.overlayRef);
        this.componentRef.changeDetectorRef.detectChanges();
    }

    ngOnChanges() {
        this.qaSelectors = expandInput(this.qa);
        Object.keys(this.qaSelectors).forEach(key => {
            const attributes = expandInput(this.qaSelectors)[key];
            this.el.nativeElement.setAttribute(getAttributeName(key), '');
            Object.keys(attributes).forEach(attribute => {
                const htmlAttrValue = attributes[attribute] || '';
                this.el.nativeElement.setAttribute(getAttributeName(key, attribute), htmlAttrValue);
            });
        });
    }
}
