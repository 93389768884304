<div class="modal">
    <modal-header
        [title]="'View JSON'"
        [hasBorder]="false"
    ></modal-header>
    <div class="modal-body modal-body--tabs fixed-height500 flex">
        <mat-tab-group #tabs>
            <mat-tab label="Card params">
                <pre class="result-box">{{ paramsJson }}</pre>
            </mat-tab>
            <mat-tab label="Card results">
                <pre class="result-box">{{ resultsJson }}</pre>
            </mat-tab>
            <mat-tab label="Computation">
                <pre class="result-box">{{ computationJson }}</pre>
            </mat-tab>
            <mat-tab label="Computation results">
                <pre  class="result-box" matTooltip="Retrieved from computation cache">{{ computationResultsJson }}</pre>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="modal-footer modal-footer-std-buttons">
        <div class="pull-right">
            <button type="button"
                mat-raised-button
                (click)="copyToClipboard()"
                class="btn btn--text btn--secondary">
                Copy to clipboard
            </button>
            <button
                type="button"
                (click)="dialogRef.close()"
                class="btn btn--text btn--secondary"
                tabindex="-1"
            >
                Close
            </button>
        </div>
    </div>
</div>
