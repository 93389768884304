import {Abstract2SampTestCard, _Abstract2SampTestCard} from './common/abstract2-samp-test-card';
import {CardResult} from './card-result';
import {Card} from './card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.MoodTest2SampCard
 */
export interface MoodTest2SampCard extends _Abstract2SampTestCard {
    // PolyJson type
    type: 'mood_test_2samp';
}

export function isMoodTest2SampCard(toTest: Card): toTest is MoodTest2SampCard { return 'mood_test_2samp' === toTest.type; }

export namespace MoodTest2SampCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.MoodTest2SampCard$MoodTest2SampCardResult
     */
    export interface MoodTest2SampCardResult extends Abstract2SampTestCard._Abstract2SampTestCardResult {
        pvalue: number;
        statistic: number;

        // PolyJson type
        type: 'mood_test_2samp';
    }

    export function isMoodTest2SampCardResult(toTest: CardResult): toTest is MoodTest2SampCardResult { return 'mood_test_2samp' === toTest.type; }
}