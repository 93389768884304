import { UsabilityComputer } from 'src/generated-sources';
import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class PrivateFlowAPI {
    constructor(private dkuHttp: DkuHttpService) {
    }

    listUsableComputables(projectKey?: string, filter?: UsabilityComputer.ListSettings): Observable<UsabilityComputer.UsableComputable[]> {
        return this.dkuHttp.request('GET', '/flow/list-usable-computable', { projectKey, filter: JSON.stringify(filter) });
    }
}
