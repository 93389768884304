<button
    class="btn header-button trigger subnav-page-header-name has-caret"
    [dkuPopoverTrigger]="popover"
    [wt1Click]="'worksheets_sampling-button'"
>
    Sampling and filtering
    <b class="caret"></b>
</button>
<dku-popover #popover>
    <div class="dkuform-horizontal">
        <form [formGroup]="form" class="sample-settings" [class.form--disabled]="readOnly">
            <fieldset [disabled]="readOnly">
                <div class="control-group">
                    <label
                        for="samplingMethod"
                        class="control-label control-dropdown-label"
                    >
                        Sampling method
                    </label>
                    <div class="controls">
                        <dku-bs-select-form-control
                            ngOptions="x[0] as x[1] for x in list"
                            [list]="samplingMethods"
                            [layout]="'list'"
                            [optionsDescriptions]="samplingMethodDescriptions"
                            formControlName="samplingMethod"
                        ></dku-bs-select-form-control>
                    </div>
                </div>
                <partition-selection *ngIf="partitioned" [(partitionSelectionMethod)]="form.value.partitionSelectionMethod"
                    [(selectedPartitions)]="form.value.selectedPartitions"
                    [(latestPartitionsN)]="form.value.latestPartitionsN"
                    [projectKey]="projectKey"
                    [datasetSmartName]="dataSpec.inputDatasetSmartName"></partition-selection>

                <div class="control-group" *ngIf="form.value.samplingMethod == 'HEAD_SEQUENTIAL'">
                    <label
                        for="maxRecords"
                        class="control-label control-dropdown-label"
                    >
                        Number of records
                    </label>
                    <div class="controls">
                        <input
                            formControlName="maxRecords"
                            name="maxRecords"
                            min="1"
                            type="number"
                        >
                    </div>
                </div>
                <div class="control-group" *ngIf="form.value.samplingMethod == 'RANDOM_FIXED_RATIO'">
                    <label
                        for="targetRatio"
                        class="control-label control-dropdown-label"
                    >
                        Ratio
                    </label>
                    <div class="controls">
                        <input
                            formControlName="targetRatio"
                            name="targetRatio"
                            min="0"
                            max="1"
                            step="0.1"
                            type="number"
                        >
                    </div>
                </div>
                <div class="control-group">
                    <h2
                        class="settings-section-title"
                    >
                    Filtering
                </h2>
                <fieldset>
                    <label>
                        <input
                            formControlName="enableFiltering"
                            name="enableFiltering"
                            type="checkbox"
                            id="enableFiltering"
                            class="form__checkbox"
                            (change)="onFilteringChange()"
                        >
                        Enable filtering
                    </label>
                </fieldset>
                </div>
                <div *ngIf="form.value.enableFiltering" [innerHTML]="form.value.filter | filterNiceRepr" (click)="onFilteringChange()" class="shaker-preview filter-expression" matTooltip="Click to edit" matTooltipPosition="right"></div>

                <dataset-selection-ordering-fields [datasetFullInfo]="datasetFullInfo" [selection]="form.value.datasetSelectionForOrdering" class="form__dataset-selection common-styles-only">

                </dataset-selection-ordering-fields>


                <div class="control-group">
                    <h2
                        class="settings-section-title"
                    >
                        Advanced
                    </h2>
                    <fieldset>
                        <label
                            for="autoRefreshSample"
                            class="control-label control-dropdown-label"
                        >
                            <input
                                formControlName="autoRefreshSample"
                                name="autoRefreshSample"
                                id="autoRefreshSample"
                                class="form__checkbox"
                                type="checkbox"
                            >
                            Auto Refresh Sample
                        </label>
                    </fieldset>
                </div>

                <div #closeButton *ngIf="!readOnly">
                    <button
                        class="btn btn--primary pull-right"
                        style="margin-right: 10px"
                        [disabled]="form.invalid"
                        (click)="save()"
                    >
                        Save and refresh sample
                    </button>
                </div>
            </fieldset>
        </form>
    </div>
</dku-popover>
