import {Distribution, _Distribution} from './distribution';

/**
 * Generated from com.dataiku.dip.eda.compute.distributions.Pareto
 */
export interface Pareto extends _Distribution {
    // PolyJson type
    type: 'pareto';
}

export function isPareto(toTest: Distribution): toTest is Pareto { return 'pareto' === toTest.type; }

export namespace Pareto {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.Pareto$FittedPareto
     */
    export interface FittedPareto extends Distribution._FittedDistribution {
        scale: number;
        shape: number;

        // PolyJson type
        type: 'pareto';
    }

    export function isFittedPareto(toTest: Distribution.FittedDistribution): toTest is FittedPareto { return 'pareto' === toTest.type; }
}